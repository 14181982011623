import { useQueryFetch } from "./useFetch";
import { UseMutationResult, useMutation, UseQueryResult, useQuery } from "react-query";
import { queryClient } from "..";
export interface Employee {
  firstname: string;
  lastname: string;
  email: string;
  language: string[];
  password: string;
}

export interface UpdateEmployee {
  id?: string;
  firstname: string;
  lastname: string;
  email: string;
  language: string[];
  password?: string;
}
export interface EmployeeInfo extends Employee {
  id: string;
}
export interface ReportInfo {
  name: string;
  editedCustomerRequests: [
    {
      mediaType: string;
      number: string;
    },
  ];
}

// Get Team
export const useAllTeam = (): UseQueryResult<EmployeeInfo[], Error> => {
  const queryFetch = useQueryFetch<EmployeeInfo[]>();

  return useQuery<EmployeeInfo[], Error>(
    ["teamRequestData"],
    () => {
      return queryFetch(process.env.REACT_APP_SERVER + "/backoffice/employee");
    },
    { keepPreviousData: false },
  );
};

// Get Employee
export const useEmployee = (employeeId: string): UseQueryResult<EmployeeInfo, Error> => {
  const queryFetch = useQueryFetch<EmployeeInfo>();
  return useQuery<EmployeeInfo, Error>(
    ["employeeRequestData", employeeId],
    () => {
      return queryFetch(process.env.REACT_APP_SERVER + `/backoffice/employee/${employeeId}`, {
        method: "GET",
      });
    },
    { keepPreviousData: false },
  );
};

// Add Employee
export const useAddEmployee = (): UseMutationResult<null, Error, Employee> => {
  const queryFetch = useQueryFetch<null>();

  return useMutation<null, Error, Employee>(
    (data) => {
      return queryFetch(process.env.REACT_APP_SERVER + `/backoffice/employee`, {
        method: "POST",
        body: JSON.stringify(data),
      });
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries();
      },
    },
  );
};

// Update Employee
export const useUpdateEmployee = (employeeId: string): UseMutationResult<null, Error, UpdateEmployee> => {
  const queryFetch = useQueryFetch<null>();

  return useMutation<null, Error, UpdateEmployee>((data) => {
    return queryFetch(process.env.REACT_APP_SERVER + `/backoffice/employee/${employeeId}`, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  });
};

export const useReport = (date: Date): UseQueryResult<ReportInfo[], Error> => {
  const queryFetch = useQueryFetch<ReportInfo[]>();
  return useQuery<ReportInfo[], Error>(
    ["reportRequestData"],
    () => {
      return queryFetch(process.env.REACT_APP_SERVER + `/backoffice/report/${date.getFullYear() + "/" + (date.getMonth() + 1)}`, {
        method: "GET",
      });
    },
    { keepPreviousData: false },
  );
};
