import { Box, Container, IconButton, TextField, MenuItem, Tooltip } from "@mui/material";
import { FC, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { userCountryList } from "../assets/data/Countries";
import { UserInfo, useUpdateUser, useUser } from "../clients/userClient";
import SaveIcon from "@mui/icons-material/Save";
import { allLanguages } from "../assets/data/Languages";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

const EditUser: FC = () => {
  const navigate = useNavigate();
  const [country, setCountry] = useState("");
  const [language, setLanguage] = useState("");

  const { register, reset, handleSubmit } = useForm<UserInfo>();
  const { id } = useParams<{ id: string }>();
  const userResult = useUser(id ?? "");
  const updateUser = useUpdateUser(id ?? "");

  useEffect(() => {
    if (userResult.status === "success") {
      reset(userResult.data);
      setCountry(userResult.data.country);
      setLanguage(userResult.data.language);
    }
  }, [userResult.data, userResult.status, reset]);

  const handleChangeCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCountry(event.target.value);
  };
  const handleChangeLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLanguage(event.target.value);
  };

  return (
    <Box flexGrow="1" sx={{ mt: 5 }}>
      <Container maxWidth="lg">
        <form
          onSubmit={handleSubmit((data) => {
            updateUser.mutate(data);
          })}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton aria-label="back" onClick={() => navigate(`/user`)}>
              <Tooltip title="Verwerfen">
                <ArrowBackRoundedIcon color="primary" fontSize="large" />
              </Tooltip>
            </IconButton>
            <IconButton aria-label="save" type="submit">
              <Tooltip title="Speichern">
                <SaveIcon color="primary" fontSize="large" />
              </Tooltip>
            </IconButton>
          </Box>
          <Box display="flex" flex="1">
            <Box flex="1">
              <TextField
                {...register("company", { required: true })}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                label="Firma"
                variant="standard"
              />
            </Box>
          </Box>
          <Box display="flex" flex="1">
            <Box flex="1">
              <TextField
                {...register("firstName", { required: true })}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                label="Vorname"
                variant="standard"
              />
            </Box>
            <Box flex="1" sx={{ ml: 2 }}>
              <TextField
                {...register("lastName", { required: true })}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                label="Name"
                variant="standard"
              />
            </Box>
          </Box>
          <Box display="flex" flex="1" justifyContent="space-between" flexWrap="wrap">
            <Box flex="1">
              <TextField
                {...register("country")}
                fullWidth
                select
                label="Land"
                variant="standard"
                value={country}
                onChange={handleChangeCountry}>
                {userCountryList.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box flex="1" sx={{ ml: 2 }}>
              <TextField
                {...register("city")}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                label="Stadt"
                variant="standard"
              />
            </Box>
          </Box>
          <Box display="flex" flex="1" flexWrap="wrap">
            <Box flex="1">
              <TextField
                {...register("phoneNumber", { required: true })}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                label="Tel."
                variant="standard"
              />
            </Box>
            <Box flex="1" sx={{ ml: 2 }}>
              <TextField
                {...register("language")}
                fullWidth
                select
                label="Sprache"
                variant="standard"
                value={language}
                onChange={handleChangeLanguage}>
                {allLanguages.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
          <Box display="flex" flex="1" flexWrap="wrap">
            <Box flex="1">
              <TextField
                {...register("favoriteLocation")}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                label="Filiale"
                variant="standard"
              />
            </Box>
          </Box>
        </form>
      </Container>
    </Box>
  );
};

export default EditUser;
