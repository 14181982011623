import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router";
import { ReactElement } from "react";
import Dashboard from "./Dashboard";
import News from "./news/News";
import User from "./User";
import Location from "./Location";
import Team from "./team";
import Logout from "./Logout";
import CustomerRequests from "./CustomerRequest/CustomerRequests";
import Report from "./report/Report";
import { Avatar } from "@mui/material";
import logo from "../assets/img/logo-weiss.png";

export interface RouteInfo {
  path: string;
  title: string;
  component: ReactElement;
  icon?: ReactElement;
}
export const routes: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    component: <Dashboard />,
  },
  {
    path: "/requests",
    title: "Anfragen",
    component: <CustomerRequests />,
  },
  {
    path: "/news",
    title: "News & Aktionen",
    component: <News />,
  },
  {
    path: "/user",
    title: "User",
    component: <User />,
  },
  {
    path: "/location",
    title: "Standorte",
    component: <Location />,
  },
  {
    path: "/team",
    title: "Mitarbeiter",
    component: <Team />,
  },
  { path: "/report", title: "Auswertung", component: <Report /> },
];

export const routesSetting: RouteInfo[] = [
  {
    path: "/logout",
    title: "Logout",
    component: <Logout />,
  },
];

const Navigation = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ top: 0 }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box component="img" sx={{ mr: 2, display: { xs: "none", md: "flex", maxHeight: 50 } }} src={logo} />
            <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit">
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}>
                {routes.map((page) => (
                  <MenuItem key={page.title} onClick={() => navigate(`${page.path}`)}>
                    <Typography textAlign="center">{page.title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none", flex: 1, justifyContent: "center" } }}>
              <Box component="img" sx={{ display: { xs: "flex", md: "none", maxHeight: 50 } }} src={logo} />
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {routes.map((page) => (
                <MenuItem key={page.title} onClick={() => navigate(`${page.path}`)} sx={{ my: 2, color: "white", display: "block" }}>
                  {page.title}
                </MenuItem>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {routesSetting.map((setting) => (
                <MenuItem key={setting.title}>
                  <Typography textAlign="center" onClick={() => navigate(`../${setting.path}`)}>
                    {setting.title}
                  </Typography>
                </MenuItem>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};
export default Navigation;
