import {
  Box,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import React, { FC, useEffect, useState } from "react";
import LocationFleetDialog from "./LocationFleetDialog";
import { Controller, useForm } from "react-hook-form";
import { LocationInfo, useLocation, useUpdateLocation } from "../clients/locationClient";
import { useNavigate, useParams } from "react-router-dom";
import { portfolioList } from "../assets/data/PortfolioList";
import { countryList } from "../assets/data/Countries";

export interface Fleet {
  fleetName: string;
  fleetAmount: number;
}

const EditLocation: FC = () => {
  const [country, setCountry] = useState("");
  const [fleet, setFleet] = useState<Fleet[]>([]);
  const [portfolio, setPortfolio] = useState<string[]>([]);
  const [showDialog, setShowDialog] = useState(false);

  const {
    register,
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<LocationInfo>();
  const { id } = useParams<{ id: string }>();
  const locationResult = useLocation(id ?? "test");
  const updateLocation = useUpdateLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (locationResult.status === "success") {
      reset(locationResult.data);
      setPortfolio(locationResult.data.portfolio);
      setCountry(locationResult.data.country);
      const fleetList: Fleet[] = locationResult.data.fleet?.map((fleetItem) => {
        const [first, ...rest] = fleetItem.split(" ");
        const remainder = rest.join(" ");
        return { fleetAmount: Number(first), fleetName: remainder } as Fleet;
      });
      setFleet(fleetList);
    }
  }, [locationResult.data, locationResult.status, reset]);

  const handleChangeCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCountry(event.target.value);
    setValue("country", country);
  };

  const handleChangePortfolio = (event: SelectChangeEvent<typeof portfolio>) => {
    const {
      target: { value },
    } = event;
    setPortfolio(typeof value === "string" ? value.split(",") : value);
    setValue("portfolio", typeof value === "string" ? value.split(",") : value);
  };

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };

  return (
    <Box flexGrow="1" sx={{ mt: 5 }}>
      <Container maxWidth="lg">
        <form
          onSubmit={handleSubmit((data) => {
            data.country = country;
            const fleetString: string[] = [];
            fleet.forEach((fleetItem) => fleetString.push(fleetItem.fleetAmount + " " + fleetItem.fleetName));
            data.fleet = fleetString;
            if (!data.fleetDescription) {
              data.fleetDescription = "No description available";
            }
            if (!data.googleMapLocation) {
              data.googleMapLocation = "No map data available";
            }
            if (!data.businessHours) {
              data.businessHours = "No business hours available";
            }
            updateLocation.mutate(data);
            navigate("../location");
          })}>
          <Box display="flex" justifyContent="flex-end">
            <FormControlLabel
              label="Online"
              control={
                <Controller
                  name="isOnline"
                  control={control}
                  render={({ field }) => <Switch onChange={(e) => field.onChange(e.target.checked)} checked={field.value ?? false} />}
                />
              }
            />

            <IconButton aria-label="save" type="submit">
              <SaveIcon color="primary" fontSize="large" />
            </IconButton>
          </Box>
          <Box display="flex" flex="1">
            <Box flex="1">
              <TextField
                {...register("name", { required: true })}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                label="Name"
                variant="standard"
              />
            </Box>
          </Box>
          <Box display="flex" flex="1" justifyContent="space-between" flexWrap="wrap">
            <Box flex="1">
              <TextField fullWidth select label="Land" variant="standard" value={country} onChange={handleChangeCountry}>
                {countryList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box flex="1" sx={{ mx: 2 }}>
              <TextField
                {...register("postCode", { required: true })}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                type="number"
                fullWidth
                label="PLZ"
                variant="standard"
              />
            </Box>
            <Box flex="1">
              <TextField
                {...register("city", { required: true })}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                label="Ort"
                variant="standard"
              />
            </Box>
          </Box>
          <Box display="flex" flex="1">
            <Box flex="1">
              <TextField
                {...register("street", { required: true })}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                label="Straße"
                variant="standard"
              />
            </Box>
          </Box>
          <Box display="flex" flex="1" justifyContent="space-between" flexWrap="wrap">
            <Box flex="1">
              <TextField
                {...register("phone", { required: true })}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                type="number"
                fullWidth
                label="Tel."
                variant="standard"
              />
            </Box>
            <Box flex="1" sx={{ ml: 2 }}>
              <TextField
                {...register("fax", { required: true })}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                type="number"
                fullWidth
                label="Fax"
                variant="standard"
              />
            </Box>
          </Box>
          <Box display="flex" flex="1">
            <TextField
              {...register("email", { pattern: /^\S+@\S+$/i })}
              error={!!errors.email}
              helperText={errors.email ? <Typography variant="h4">Bitte eine gültige E-Mail Adresse eingeben</Typography> : ""}
              InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
              fullWidth
              label="E-Mail"
              variant="standard"
            />
          </Box>
          <Box display="flex" flex="1">
            <Box flex="1">
              <TextField
                {...register("longitude", { required: true })}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                label="Längengrad"
                variant="standard"
              />
            </Box>
            <Box flex="1" sx={{ ml: 2 }}>
              <TextField
                {...register("latitude", { required: true })}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                label="Breitengrad"
                variant="standard"
              />
            </Box>
          </Box>
          <Box display="flex" flex="1">
            <Box flex="1">
              <FormControl variant="standard" fullWidth>
                <InputLabel>Portfolio</InputLabel>
                <Select multiple value={portfolio} onChange={handleChangePortfolio} renderValue={(selected) => selected.join(", ")}>
                  {portfolioList.map((portfolioEntry) => (
                    <MenuItem key={portfolioEntry} value={portfolioEntry}>
                      <Checkbox checked={portfolio.indexOf(portfolioEntry) > -1} />
                      <ListItemText primary={portfolioEntry} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Typography variant="body2">Fuhrpark</Typography>
          </Box>
          <Box display="flex" flex="1" sx={{ mt: 5 }}>
            <Box display="flex" flexDirection="row" flex="1" justifyContent="space-between">
              <Box flexDirection="column">
                {fleet.map((showFleet, index) => (
                  <Box key={index}>
                    {showFleet.fleetAmount + " "}
                    {showFleet.fleetName}
                  </Box>
                ))}
              </Box>
              <Box display="flex" alignItems="center">
                <IconButton aria-label="add" onClick={toggleDialog}>
                  <Tooltip title="Fahrzeug hinzufügen">
                    <AddCircleOutlineIcon color="primary" />
                  </Tooltip>
                </IconButton>
              </Box>
              <LocationFleetDialog isOpen={showDialog} onSave={setFleet} fleets={fleet} onChange={toggleDialog} />
            </Box>
          </Box>
        </form>
      </Container>
    </Box>
  );
};

export default EditLocation;
