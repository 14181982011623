export const fleetList = [
  "3-Achser Planen-Lkw mit 27 m Kranausleger",
  "3-Achser Planen-Lkw mit 34m Kranausleger und Drehkopfwendegabel",
  "3-Achser Planen-Lkw mit Mitnahmestapler",
  "4-Achser Planen-Lkw mit 34m Kranausleger und Drehkopfwendegabel",
  "7.5 t 'City-Flitzer' mit 3 t Nutzlast",
  "Anhänger",
  "City-Flitzer mit 3 t Nutzlast",
  "Hochlogistikfahrzeuge",
  "Hochlogistikkranfahrzeuge mit Drehkopfwendegabel",
  "Kranfahrzeug mit 22 m Kranausleger und Drehkopfwendegabel",
  "Kranfahrzeug mit 27 m Hochlogistikkran",
  "Kranfahrzeug mit Ausleger bis 32 m (Offenes Fahrzeug)",
  "Kranfahrzeuge mit 24 m Kranausleger und Drehkopfwendegabel",
  "Kranfahrzeuge mit Ausleger bis 27 m Hubhöhe und Drehkopfwendegabel",
  "Kranfahrzeuge mit Ausleger bis 28 m Hubhöhe und Drehkopfwendegabel",
  "Kranfahrzeuge mit Ausleger bis 29 m Hubhöhe und Drehkopfwendegabel",
  "Kranfahrzeuge mit Ausleger bis 34 m Hubhöhe und Drehkopfwendegabel",
  "Kranfahrzeuge mit Auslegern bis 21 m Hubhöhe und Drehkopfwendegabel",
  "Kranfahrzeuge mit Auslegern bis 24 m Hubhöhe und Drehkopfwendegabel",
  "Kranfahrzeuge mit Auslegern bis 25 m Hubhöhe und Drehkopfwendegabel",
  "Kranfahrzeuge mit Auslegern bis 25 m und Drehkopfwendegabel",
  "Kranfahrzeuge mit Auslegern bis 27 m Hubhöhe und Drehkopfwendegabel",
  "Kranfahrzeuge mit Auslegern bis 28 m Hubhöhe und Drehkopfwendegabel",
  "Kranfahrzeuge mit Auslegern bis 31 m Hubhöhe und Drehkopfwendegabel",
  "Kranfahrzeuge mit Auslegern bis 32 m Hubhöhe und Drehkopfwendegabel",
  "Kranfahrzeuge mit Auslegern bis 33 m Hubhöhe und Drehkopfwendegabel",
  "Kranfahrzeuge mit Auslegern bis 34 m Hubhöhe",
  "Kranfahrzeuge mit Auslegern bis 34 m Hubhöhe und Drehkopfwendegabel",
  "Kranfahrzeuge mit Auslegern bis 35 m Hubhöhe und Drehkopfwendegabel",
  "Kranfahrzeuge mit Auslegern bis 41 m Hubhöhe und Drehkopfwendegabel",
  "Kurierfahrzeug",
  "Kurierfahrzeuge",
  "Leihanhänger",
  "Lkw mit geländegängigem Mitnahmestapler",
  "Mercedes Sprinter",
  "Mitnahmestapler",
  "Planen-Anhänger",
  "Planen-Lkw (12 Tonnen) mit Hebebühne",
  "Planen-Lkw mit Hebebühne",
  "Planen-Lkw mit Mitnahmestapler",
  "Planen-Lkw mit geländegängigem Mitnahmestapler",
  "Planen-Lkw mit geländegängigem Mitnahmestapler1 Anhänger bis zu 14 t",
  "Planen-Lkw mit geländegängigem Mitnahmestapler1 Planen-Lkw mit Hebebühne",
  "Planenanhänger",
  "Planenfahrzeug 12 t mit Hebebühne",
  "Planenfahrzeug 7.5 t",
  "Planenfahrzeug mit Anhänger",
  "Planenfahrzeug mit Hebebühne",
  "Planenfahrzeug mit Mitnahmestapler",
  "Planenfahrzeug mit geländegängigem Mitnahmestapler",
  "Planenfahrzeug mit geländegängigem Vierwege-Mitnahmestapler 'Crayler'",
  "Planenfahrzeug mit geländegängigen Mitnahmestapler 'Crayler'",
  "Planenfahrzeuge",
  "Planenfahrzeuge 12.5 t mit Hebebühne",
  "Planenfahrzeuge 7.5 t mit Hebebühne",
  "Planenfahrzeuge mit Hebebühne",
  "Planenfahrzeuge mit Hebebühne1 Planenfahrzeug mit geländegängigem Mitnahmestapler",
  "Planenfahrzeuge mit Mitnahmestapler",
  "Planenfahrzeuge mit geländegängigem Mitnahmestapler",
  "Planfahrzeuge mit geländegängigem Mitnahmestapler",
  "Sprinter",
  "Sprinter Klein-Lkw",
];
