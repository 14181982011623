import {
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  CircularProgress,
  Stack,
  TablePagination,
  NativeSelect,
  InputLabel,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { countries } from "../assets/data/Countries";

import { useAllLocations, LocationInfo } from "../clients/locationClient";
import ErrorMessage from "../components/ErrorMessage";

const Location: FC = () => {
  const navigate = useNavigate();

  const locationsResult = useAllLocations();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState("");
  const [tableData, setTableData] = useState<LocationInfo[]>([]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (locationsResult.status === "success") {
      setTableData(locationsResult.data);
    }
  }, [locationsResult.data, locationsResult.status]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelected(event.target.value);
    if (locationsResult.status === "success") {
      const filteredLocations = event.target.value
        ? locationsResult.data.filter((location) => location.country === event.target.value)
        : locationsResult.data;
      setTableData(filteredLocations);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 3 }}>
      <>
        <Box alignItems="center" justifyContent="space-between" display="flex">
          <Box>
            <Typography variant="caption" style={{ fontWeight: 600 }}>
              <h1>Standorte</h1>
            </Typography>
          </Box>
        </Box>

        <Box sx={{ mt: 2, p: 1 }}>
          <InputLabel variant="standard">Land</InputLabel>
          <NativeSelect style={{ width: "100%" }} sx={{ mb: 2 }} value={selected} onChange={handleChange} name="country">
            {countries.map((country) => (
              <option key={country.value} value={country.value}>
                {country.label}
              </option>
            ))}
          </NativeSelect>

          {locationsResult.status === "success" ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="medium">
                <TableHead style={{ padding: 0 }}>
                  <TableRow
                    style={{
                      whiteSpace: "nowrap",
                      backgroundColor: grey[400],
                    }}>
                    <TableCell align="center" width="25%">
                      <Typography variant="h5">Name</Typography>
                    </TableCell>
                    <TableCell align="center" width="25%">
                      <Typography variant="h5">Straße</Typography>
                    </TableCell>
                    <TableCell align="center" width="25%">
                      <Typography variant="h5">PLZ</Typography>
                    </TableCell>
                    <TableCell align="center" width="25%">
                      <Typography variant="h5">Ort</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <TableRow
                      key={row.name}
                      onClick={() => {
                        navigate("../editlocation/" + row.id.toString());
                      }}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell align="center" component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="center">{row.street}</TableCell>
                      <TableCell align="center">{row.postCode}</TableCell>
                      <TableCell align="center">{row.city}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={
                  selected
                    ? locationsResult.data.filter((item) => {
                        return item.country === selected;
                      }).length
                    : locationsResult.data.length
                }
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          ) : locationsResult.status === "error" ? (
            <ErrorMessage messageId={locationsResult.error.toString()} />
          ) : (
            <Stack alignItems="center">
              <CircularProgress />
            </Stack>
          )}
        </Box>
      </>
    </Container>
  );
};

export default Location;
