import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";

import React, { FC, useEffect, useState } from "react";
import { fleetList } from "../assets/data/FleetList";
import { Fleet } from "./EditLocation";
import FleetDialogInput from "./FleetDialogInput";

interface LocationFleetDialogProps {
  isOpen: boolean;
  onSave: (fleets: Fleet[]) => void;
  onChange: () => void;
  fleets: Fleet[];
}

const LocationFleetDialog: FC<LocationFleetDialogProps> = ({ isOpen, onSave, onChange, fleets }: LocationFleetDialogProps) => {
  const [fleetSelect, setFleetSelect] = useState<Fleet[]>(fleets);

  useEffect(() => {
    setFleetSelect(fleets);
  }, [fleets]);

  return (
    <Dialog maxWidth="md" fullWidth open={isOpen}>
      <DialogTitle>Fahrzeuge hinzufügen</DialogTitle>
      <DialogContent>
        <Box
          noValidate
          component="form"
          display="flex"
          flexDirection="row"
          sx={{
            m: "auto",
            width: "fit-content",
          }}>
          <Box display="flex" flexDirection="column">
            {fleetSelect.length > 0 ? (
              fleetSelect.map((fleet, index) => (
                <FleetDialogInput
                  key={index}
                  onDelete={(fleetItem) => {
                    setFleetSelect((prev) => {
                      const removeFleet = prev.filter((fleetSelect) => fleetSelect !== fleetItem);

                      return removeFleet;
                    });
                  }}
                  onChange={(newFleet) => {
                    setFleetSelect((prev) => {
                      const newFleetSelect = [...prev];
                      newFleetSelect[index] = newFleet;
                      return newFleetSelect;
                    });
                  }}
                  fleet={fleet}
                />
              ))
            ) : (
              <Typography variant="h5">Noch keine Fahrzeuge hinzugefügt</Typography>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" flex="1" justifyContent="space-between">
          <Box>
            <Button
              onClick={() => {
                onChange();
                setFleetSelect(fleets);
              }}>
              Abbrechen
            </Button>
          </Box>
          <Box>
            <Button
              onClick={() => {
                setFleetSelect((prev) => {
                  const newFleetSelect = [...prev];
                  newFleetSelect.push({ fleetAmount: 1, fleetName: fleetList[0] });
                  return newFleetSelect;
                });
              }}>
              Neu
            </Button>
            <Button
              onClick={() => {
                onSave(fleetSelect);
                onChange();
              }}>
              Speichern
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default LocationFleetDialog;
