import { UseQueryResult, useQuery } from "react-query";

import { useQueryFetch } from "./useFetch";

export interface DashboardData {
  unedited: number;
  edited: number;
  registeredWeeks: NewRegistrations[];
}
interface NewRegistrations {
  usersRegistered: number;
  week: string;
}

export const useDashboard = (): UseQueryResult<DashboardData, Error> => {
  const queryFetch = useQueryFetch<DashboardData>();

  return useQuery<DashboardData, Error>(["dashboardData"], () => queryFetch(process.env.REACT_APP_SERVER + "/backoffice/dashboard"));
};
