import { Box, Button, Card, Collapse, IconButton, Slider, TableCell, TableRow, TextField } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { CustomerEditRequest, CustomerRequest, usePdfDownload, useUpdateCustomerRequest } from "../../clients/customerRequestClient";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ColumnDisplayFilter } from "./CustomerRequests";
import { ArrowCircleDown, Cancel, Edit, Save } from "@mui/icons-material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import CalculateIcon from "@mui/icons-material/Calculate";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { useEmployee } from "../../clients/teamClient";
import { useNavigate } from "react-router-dom";
import PDFDownload from "./PDFDownload";

interface CustomerRequestRowProps {
  row: CustomerRequest;
  requestId: string;
  employeeId: string;
  filter: ColumnDisplayFilter;
  onRowChange?: (row: CustomerRequest) => void;
}

const marks = [
  {
    value: 0,
    label: "nicht bearbeitet",
  },
  {
    value: 50,

    label: "in Bearbeitung",
  },
  {
    value: 100,
    label: "abgeschlossen",
  },
];

const CustomerRequestRow: FC<CustomerRequestRowProps> = ({ row, requestId, employeeId, filter, onRowChange }: CustomerRequestRowProps) => {
  const [editMode, setEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [requestStatus, setRequestStatus] = useState<number | number[]>(0);
  const request = useUpdateCustomerRequest(requestId);
  const navigate = useNavigate();
  const employee = useEmployee(employeeId);
  const [requestData, setRequestData] = useState<CustomerEditRequest>();

  useEffect(() => {
    switch (row.statusRequest) {
      case "unedited":
        setRequestStatus(0);
        break;
      case "inProgress":
        setRequestStatus(50);
        break;
      case "edited":
        setRequestStatus(100);
        break;
      default:
        break;
    }
  }, [row]);

  const requestStatusToText = (status: string): string => {
    switch (status) {
      case "unedited":
        return "nicht bearbeitet";
      case "inProgress":
        return "in Bearbeitung";
      case "edited":
        return "abgeschlossen";
      default:
        return "error";
    }
  };

  return (
    <>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell
          sx={{ display: filter.Typ }}
          component="th"
          scope="row"
          onClick={() => {
            navigate(`/answerCustomerRequest/${row.userId}`);
          }}>
          {row.mediaType === "audio" ? (
            <PlayCircleOutlineIcon />
          ) : row.mediaType === "MaterialRequest" ? (
            <CalculateIcon />
          ) : (
            <InsertPhotoIcon />
          )}
        </TableCell>
        <TableCell
          sx={{ display: filter.Status }}
          align="left"
          onClick={() => {
            navigate(`/answerCustomerRequest/${row.userId}`);
          }}>
          {requestStatusToText(row.statusRequest)}
        </TableCell>
        <TableCell
          sx={{ display: filter.Sprache }}
          align="left"
          onClick={() => {
            navigate(`/answerCustomerRequest/${row.userId}`);
          }}>
          {row.language}
        </TableCell>
        <TableCell
          sx={{ display: filter.Firma }}
          align="left"
          onClick={() => {
            navigate(`/answerCustomerRequest/${row.userId}`);
          }}>
          {row.customerCompany}
        </TableCell>
        <TableCell
          sx={{ display: filter.Kundenname }}
          align="left"
          onClick={() => {
            navigate(`/answerCustomerRequest/${row.userId}`);
          }}>
          {row.customerName}
        </TableCell>
        <TableCell
          sx={{ display: filter.Empfangen }}
          align="left"
          onClick={() => {
            navigate(`/answerCustomerRequest/${row.userId}`);
          }}>
          {row.creationDate ? new Date(row.creationDate).toLocaleDateString("de-De") : ""}
        </TableCell>
        <TableCell
          sx={{ display: filter.Bearbeitet }}
          align="left"
          onClick={() => {
            navigate(`/answerCustomerRequest/${row.userId}`);
          }}>
          {row.completionDate ? new Date(row.completionDate).toLocaleDateString("de-De") : ""}
        </TableCell>
        <TableCell
          sx={{ display: filter.Beschreibung }}
          align="left"
          onClick={() => {
            navigate(`/answerCustomerRequest/${row.userId}`);
          }}>
          {row.materialRequest
            ? row.materialRequest.area +
              " / " +
              row.materialRequest.selection +
              " / " +
              row.materialRequest.materialOrder.map((row) => row.name + " / " + row.unit + " / " + row.amount)
            : ""}
        </TableCell>
        <TableCell align="right" onClick={() => setOpen(!open)}>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 8, backgroundColor: "#e6e6e6" }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box display="flex" justifyContent="flex-start" sx={{ my: 1 }}>
              <Box flex="2" display="flex" flexDirection="column" flexWrap="nowrap">
                <Card sx={{ p: 1 }}>
                  <Box display="flex" flexDirection="column" flexWrap="nowrap">
                    <TextField
                      focused={false}
                      inputProps={{ readOnly: true }}
                      value={
                        row.materialRequest
                          ? row.materialRequest.area +
                            " / " +
                            row.materialRequest.selection +
                            " / " +
                            row.materialRequest.materialOrder.map((row) => row.name + " / " + row.unit + " / " + row.amount)
                          : ""
                      }
                      variant="standard"
                      size="small"
                      label="Beschreibung"
                    />
                    {!editMode ? (
                      <Box alignSelf="end">
                        <IconButton
                          aria-label="roleEditButton"
                          color="default"
                          onClick={() => {
                            setEditMode(!editMode);
                          }}>
                          <Edit />
                        </IconButton>
                      </Box>
                    ) : editMode ? (
                      <Box display="flex" alignSelf="end">
                        <IconButton
                          aria-label="roleSaveButton"
                          color="default"
                          onClick={() => {
                            setEditMode(!editMode);
                            const updatedCustomerRequest = { ...row };
                            onRowChange?.(updatedCustomerRequest);
                            setRequestData({ ...requestData, employeeNotes: row.employeeNotes });
                            request.updateCustomerRequest({ ...requestData, employeeNotes: row.employeeNotes });
                          }}>
                          <Save />
                        </IconButton>
                        <IconButton
                          aria-label="roleCancelButton"
                          color="default"
                          onClick={() => {
                            setEditMode(!editMode);
                          }}>
                          <Cancel />
                        </IconButton>
                      </Box>
                    ) : null}
                    <TextField
                      inputProps={{ readOnly: !editMode }}
                      InputProps={{ disableUnderline: true }}
                      defaultValue={row.employeeNotes}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        row.employeeNotes = event.target.value;
                      }}
                      rows={8}
                      placeholder="Notiz"
                      variant="standard"
                      multiline
                    />
                  </Box>
                </Card>
              </Box>
              <Box flex="1" display="flex" flexDirection="column" flexWrap="nowrap" sx={{ px: 1, minWidth: 400 }}>
                <TextField
                  focused={false}
                  inputProps={{ readOnly: true }}
                  InputProps={{ disableUnderline: true }}
                  value={row.editingEmployee}
                  variant="standard"
                  size="small"
                  label="Bearbeiter"
                />
                <TextField
                  focused={false}
                  inputProps={{ readOnly: true }}
                  InputProps={{ disableUnderline: true }}
                  value={row.phoneCustomer}
                  variant="standard"
                  size="small"
                  label="Kundennummer"
                />
                <Box sx={{ width: 300 }} flex="2" display="flex" alignSelf="center" alignItems="center">
                  <Slider
                    aria-label="Custom marks"
                    value={requestStatus}
                    step={50}
                    marks={marks}
                    onChange={(event: Event, newValue: number | number[]) => {
                      if (employee.status === "success") {
                        setRequestStatus(newValue);
                        const updatedCustomerRequest = { ...row };
                        switch (newValue) {
                          case 0:
                            updatedCustomerRequest.statusRequest = "unedited";
                            updatedCustomerRequest.completionDate = "";
                            updatedCustomerRequest.editingEmployee = "";
                            setRequestData({ statusRequest: "unedited" });
                            break;
                          case 50:
                            setRequestData({ statusRequest: "inProgress" });
                            updatedCustomerRequest.statusRequest = "inProgress";
                            updatedCustomerRequest.completionDate = "";
                            updatedCustomerRequest.editingEmployee = employee.data.firstname + " " + employee.data.lastname;
                            break;
                          case 100:
                            setRequestData({ statusRequest: "edited", completionDate: new Date().toISOString() });
                            updatedCustomerRequest.statusRequest = "edited";
                            updatedCustomerRequest.completionDate = new Date().toISOString();
                            updatedCustomerRequest.editingEmployee = employee.data.firstname + " " + employee.data.lastname;
                            break;
                          default:
                            break;
                        }
                        onRowChange?.(updatedCustomerRequest);
                      }
                    }}
                    onChangeCommitted={() => {
                      if (requestData) {
                        request.updateCustomerRequest(requestData);
                      }
                    }}
                  />
                </Box>
                {row.materialRequest ? (
                  <Box flex="1" alignSelf="center" alignItems="center">
                    <PDFDownload requestId={requestId} />
                  </Box>
                ) : null}
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CustomerRequestRow;
