import { useCallback, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { Result } from "./useFetch";

export interface UserData {
  accessToken: string;
  refreshToken: string;
}

export const useLogin = (): {
  handleLogin: (name: string, password: string) => void;
  result: Result<null>;
} => {
  const [, setCookie] = useCookies(["userData"]);
  const navigate = useNavigate();
  const [result, setResult] = useState<Result<null>>({
    status: "initial",
  });

  const errorHandling = useCallback((errorCode: number | null, message: string) => {
    setResult({
      status: "error",
      responseErrorCode: errorCode,
      messageId: message,
    });
  }, []);

  const handleLogin = useCallback(
    async (name: string, password: string) => {
      setResult({ status: "loading" });
      fetch(process.env.REACT_APP_SERVER + "/backoffice/session/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({ email: name, password: password }),
      })
        .then((response) => {
          if (!response.ok) {
            errorHandling(response.status, "Fehler beim Login");
            return false;
          }
          return response.json();
        })
        .then((userData) => {
          if (userData) {
            setCookie("userData", userData.loginTokens, {
              path: "/",
            });
            setResult({
              status: "loaded",
              payload: null,
            });
            navigate("/dashboard");
          }
        })
        .catch(() => {
          errorHandling(null, "General.error.server");
        });
    },
    [errorHandling, navigate, setCookie],
  );

  return { handleLogin, result };
};
