import { ArrowCircleDown } from "@mui/icons-material";
import { Button } from "@mui/material";
import { FC } from "react";
import { usePdfDownload } from "../../clients/customerRequestClient";
import FileSaver, { saveAs } from "file-saver";
interface PDFDownloadProps {
  requestId: string;
}

const PDFDownload: FC<PDFDownloadProps> = ({ requestId }) => {
  const pdfDownload = usePdfDownload(requestId);
  const handlePdfDownload = () => {
    if (pdfDownload.data) {
      FileSaver.saveAs(pdfDownload.data);
    }
  };
  return (
    <Button variant="outlined" startIcon={<ArrowCircleDown />} onClick={handlePdfDownload}>
      PDF Download
    </Button>
  );
};

export default PDFDownload;
