import { useQueryFetch } from "./useFetch";
import { UseQueryResult, useQuery, useMutation, UseMutationResult } from "react-query";

export interface UserInfo {
  id: "string";
  company: "string";
  firstName: "string";
  lastName: "string";
  phoneNumber: "string";
  country: "string";
  favoriteLocation: "string";
  city: "string";
  language: "string";
}

// Get Users
export const useAllUsers = (): UseQueryResult<UserInfo[], Error> => {
  const queryFetch = useQueryFetch<UserInfo[]>();

  return useQuery<UserInfo[], Error>(
    ["userRequestData"],
    () => {
      return queryFetch(process.env.REACT_APP_SERVER + "/backoffice/user");
    },
    { keepPreviousData: false },
  );
};

// Get User
export const useUser = (userid: string): UseQueryResult<UserInfo, Error> => {
  const queryFetch = useQueryFetch<UserInfo>();
  return useQuery<UserInfo, Error>(
    ["userRequestData", userid],
    () => {
      return queryFetch(process.env.REACT_APP_SERVER + `/backoffice/user/${userid}`, {
        method: "GET",
      });
    },
    { keepPreviousData: false },
  );
};

// Update User
export const useUpdateUser = (userid: string): UseMutationResult<null, Error, UserInfo> => {
  const queryFetch = useQueryFetch<null>();

  return useMutation<null, Error, UserInfo>((data) => {
    return queryFetch(process.env.REACT_APP_SERVER + `/backoffice/user/${userid}`, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  });
};
