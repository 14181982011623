import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { FC, useEffect, useState } from "react";
import { visuallyHidden } from "@mui/utils";
import { NewsInfo, useAllNews } from "../../clients/newsClient";
import ErrorMessage from "../ErrorMessage";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NewsDataRow from "./NewsDataRow";
import { EnhancedTableProps, headCells } from "../../assets/data/NewsData";
import { useNavigate } from "react-router-dom";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string | string[] | boolean | Record<string, unknown> },
  b: { [key in Key]: number | string | string[] | boolean | Record<string, unknown> },
) => number {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof NewsInfo) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ padding: 0 }}>
      <TableRow
        style={{
          whiteSpace: "nowrap",
          backgroundColor: grey[400],
        }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}>
              <Typography variant="h5">{headCell.label}</Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="center" />
      </TableRow>
    </TableHead>
  );
}

const News: FC = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableData, setTableData] = useState<NewsInfo[]>([]);
  const [orderBy, setOrderBy] = useState<keyof NewsInfo>("creationDate");
  const [order, setOrder] = useState<Order>("desc");
  const newsResult = useAllNews();
  const navigate = useNavigate();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof NewsInfo) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  useEffect(() => {
    if (newsResult.status === "success") {
      setTableData(newsResult.data);
    }
  }, [newsResult.data, newsResult.status]);

  return (
    <Container maxWidth="lg" sx={{ mt: 3 }}>
      <>
        <Box alignItems="center" justifyContent="space-between" display="flex">
          <Box>
            <Typography variant="caption" style={{ fontWeight: 600 }}>
              <h1>Aktionen & News</h1>
            </Typography>
          </Box>
          <Box>
            <IconButton
              color="primary"
              onClick={() => {
                navigate("../editNews");
              }}>
              <Tooltip title="News / Aktionen hinzufügen">
                <AddCircleOutlineIcon fontSize="large" />
              </Tooltip>
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ mt: 2, p: 1 }}>
          {newsResult.status === "success" ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="medium">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={tableData.length}
                  numSelected={0}
                />

                <TableBody>
                  {stableSort(tableData, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <NewsDataRow key={row.id} row={row} />
                    ))}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={newsResult.data?.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          ) : newsResult.status === "error" ? (
            <ErrorMessage messageId={newsResult.error.toString()} />
          ) : (
            <Stack alignItems="center">
              <CircularProgress />
            </Stack>
          )}
        </Box>
      </>
    </Container>
  );
};

export default News;
