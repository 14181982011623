import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import { useQueryFetch } from "./useFetch";

export interface LocationInfo {
  country: string;
  name: string;
  street: string;
  postCode: number;
  city: string;
  latitude: string;
  longitude: string;
  email: string;
  fax: string;
  fleet: string[];
  id: string;
  isOnline: boolean;
  portfolio: string[];
  phone: string;
  googleMapLocation: string;
  businessHours: string;
  fleetDescription: string;
}

export const useAllLocations = (): UseQueryResult<LocationInfo[], Error> => {
  const queryFetch = useQueryFetch<LocationInfo[]>();

  return useQuery<LocationInfo[], Error>(
    ["locationRequestData"],
    () => {
      return queryFetch(process.env.REACT_APP_SERVER + "/backoffice/location");
    },
    { keepPreviousData: false },
  );
};

export const useLocation = (locationId: string): UseQueryResult<LocationInfo, Error> => {
  const queryFetch = useQueryFetch<LocationInfo>();
  return useQuery<LocationInfo, Error>(
    ["locationRequestData", locationId],
    () => {
      return queryFetch(process.env.REACT_APP_SERVER + `/backoffice/location/${locationId}`, {
        method: "GET",
      });
    },
    { keepPreviousData: false },
  );
};

export const useUpdateLocation = (): UseMutationResult<null, Error, LocationInfo> => {
  const queryFetch = useQueryFetch<null>();

  return useMutation<null, Error, LocationInfo>((data) => {
    return queryFetch(process.env.REACT_APP_SERVER + `/backoffice/location/${data.id}`, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  });
};
