export const countries = [
  {
    value: "",
    label: "Alle Länder",
  },
  {
    value: "DEU",
    label: "Deutschland",
  },
  {
    value: "NLD",
    label: "Niederlande",
  },
  {
    value: "AUT",
    label: "Österreich",
  },
  {
    value: "BEL",
    label: "Belgien",
  },
  {
    value: "BGR",
    label: "Bulgarien",
  },
  {
    value: "HRV",
    label: "Kroatien",
  },
  {
    value: "CZE",
    label: "Tschechien",
  },
  {
    value: "HUN",
    label: "Ungarn",
  },
  {
    value: "ITA",
    label: "Italien",
  },
  {
    value: "LUX",
    label: "Luxemburg",
  },
  {
    value: "POL",
    label: "Polen",
  },
  {
    value: "ROU",
    label: "Rumänien",
  },
  {
    value: "SRB",
    label: "Serbien",
  },
  {
    value: "SVN",
    label: "Slowenien",
  },
];

export const countryList = [
  "Österreich",
  "Belgien",
  "Bulgarien",
  "Kroatien",
  "Tschechien",
  "Deutschland",
  "Ungarn",
  "Italien",
  "Luxemburg",
  "Niederlande",
  "Polen",
  "Rumänien",
  "Serbien",
  "Slowenien",
];

export const userCountryList = [
  { value: "de", label: "Deutschland" },
  { value: "at", label: "Österreich" },
];
