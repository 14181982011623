import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { visuallyHidden } from "@mui/utils";
import { ChangeEvent, useEffect, useState } from "react";
import { Container, IconButton, Tooltip, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router";
import { EmployeeInfo, useAllTeam } from "../clients/teamClient";
import { grey } from "@mui/material/colors";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string | string[] }, b: { [key in Key]: number | string | string[] }) => number {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof EmployeeInfo;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "lastname",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "firstname",
    numeric: false,
    disablePadding: true,
    label: "Vorname",
  },
  {
    id: "language",
    numeric: false,
    disablePadding: true,
    label: "Sprache",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof EmployeeInfo) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof EmployeeInfo) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow
        style={{
          whiteSpace: "nowrap",
          backgroundColor: grey[400],
        }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}>
              <Typography variant="h5">{headCell.label}</Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function Team() {
  const navigate = useNavigate();
  const teamResult = useAllTeam();
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof EmployeeInfo>("lastname");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tableData, setTableData] = useState<EmployeeInfo[]>([]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof EmployeeInfo) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (teamResult.status === "success") {
      setTableData(teamResult.data);
    }
  }, [teamResult]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  return (
    <Container maxWidth="lg" sx={{ marginTop: 2 }}>
      <Box alignItems="center" justifyContent="space-between" display="flex">
        <Box>
          <Typography variant="caption" style={{ fontWeight: 600 }}>
            <h1>Mitarbeiter</h1>
          </Typography>
        </Box>
        <Box>
          <IconButton
            sx={{ float: "right" }}
            color="primary"
            onClick={() => {
              navigate("../addEmployee");
            }}>
            <Tooltip title="Mitarbeiter hinzufügen">
              <AddCircleOutlineIcon fontSize="large" />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ width: "100%", float: "left" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"medium"}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={tableData?.length}
                numSelected={0}
                onSelectAllClick={function (event: ChangeEvent<HTMLInputElement>): void {
                  throw new Error("Function not implemented.");
                }}
              />
              <TableBody>
                {stableSort(tableData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const addStr = (str: string, index: number, stringToAdd: string) => {
                      return str?.substring(0, index) + stringToAdd + str?.substring(index, str.length);
                    };
                    return (
                      <TableRow key={row.id} onClick={() => navigate(`/editEmployee/${row.id}`)}>
                        <TableCell component="th" id={labelId} scope="row">
                          {row.lastname}
                        </TableCell>
                        <TableCell align="left">{row.firstname}</TableCell>
                        <TableCell align="left">
                          {addStr(row.language?.toString(), row.language?.toString().indexOf(",") + 1, " ")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Container>
  );
}
