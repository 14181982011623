import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiCard: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          minWidth: 200,
          marginBottom: 30,
        },
      },
    },
  },
  typography: {
    overline: {
      fontWeight: 600,
    },
    caption: {
      fontSize: 17,
    },
    subtitle2: {
      fontSize: 11,
    },
    h4: { fontSize: 14 },
    h5: { fontSize: 14, fontWeight: 600 },
  },
});

export default theme;
