import { Box, FormControl, IconButton, InputLabel, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import React, { FC } from "react";
import { Fleet } from "./EditLocation";
import { fleetList } from "../assets/data/FleetList";

interface FleetDialogInputProps {
  fleet: Fleet;
  onChange: (fleet: Fleet) => void;
  onDelete: (fleetItem: Fleet) => void;
}

const FleetDialogInput: FC<FleetDialogInputProps> = ({ fleet, onChange, onDelete }: FleetDialogInputProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        m: "auto",
        width: "fit-content",
        flex: 1,
        maxWidth: "auto",
        p: "0 !important",
        minWidth: 175,
        overflowX: "auto",
        whiteSpace: "nowrap",
      }}>
      <FormControl variant="standard">
        <TextField
          label="Anzahl"
          type="number"
          InputProps={{ inputProps: { min: "1", max: "10", step: "1" }, style: { fontSize: 20 } }}
          variant="standard"
          sx={{ mt: 3, minWidth: 40 }}
          value={fleet.fleetAmount}
          onChange={(event) => {
            onChange({ ...fleet, fleetAmount: Number(event.target.value) });
          }}
        />
      </FormControl>

      <FormControl variant="standard" sx={{ mt: 3, ml: 2, minWidth: 700 }}>
        <InputLabel>Fahrzeuge</InputLabel>
        <Select
          value={fleet.fleetName}
          onChange={(event) => {
            onChange({ ...fleet, fleetName: event.target.value });
          }}
          size="small">
          {fleetList.map((fleetItem) => (
            <MenuItem key={fleetItem} value={fleetItem}>
              <ListItemText primary={fleetItem} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <IconButton
        aria-label="delete"
        sx={{ mt: 2, ml: 1 }}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          onDelete(fleet);
        }}>
        <DeleteOutlineIcon color="warning" />
      </IconButton>
    </Box>
  );
};

export default FleetDialogInput;
