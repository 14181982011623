import { Bar, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Button, Card, CardActions, CardContent, Chip, CircularProgress, Divider, Stack, Typography } from "@mui/material";

import { Box } from "@mui/system";
import ErrorMessage from "./ErrorMessage";
import { useDashboard } from "../clients/dashboardClient";
import { useNavigate } from "react-router";

const Dashboard = () => {
  const navigate = useNavigate();
  const dashboardInfo = useDashboard();

  return (
    <>
      {dashboardInfo.status === "success" ? (
        <>
          <Box display="flex" flexDirection="column" minWidth={300}>
            <Box flex="1" alignSelf="center">
              <Card sx={{ minWidth: 400, marginTop: 2, flex: "1" }}>
                <CardContent>
                  <Typography variant="h4">Anfragen</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Box display="flex" sx={{ mb: 2 }}>
                    <Typography flex="1" variant="h6">
                      Alle
                    </Typography>
                    <Chip
                      label={
                        dashboardInfo.data.edited && dashboardInfo.data.unedited
                          ? dashboardInfo.data.edited + dashboardInfo.data.unedited
                          : 0
                      }
                    />
                  </Box>
                  <Box display="flex">
                    <Typography flex="1" variant="h6">
                      Unbeantwortet
                    </Typography>
                    <Chip label={dashboardInfo.data.unedited ? dashboardInfo.data.unedited : 0} />
                  </Box>
                </CardContent>
                <CardActions>
                  <Button
                    aria-label="Request Button"
                    size="small"
                    onClick={() => {
                      navigate("../requests");
                    }}>
                    Zu den Anfragen
                  </Button>
                </CardActions>
              </Card>
            </Box>
          </Box>
          <Card sx={{ marginTop: 2, flex: "1", minWidth: 300, minHeight: 400 }}>
            <CardContent sx={{ flex: "1" }}>
              <Typography variant="h6">Benutzer-Registrierungen pro KW</Typography>
              <Divider sx={{ m: 2 }} />
              <div style={{ flex: "1" }}>
                <ResponsiveContainer width="100%" height={400}>
                  <ComposedChart
                    width={600}
                    height={400}
                    data={dashboardInfo.data.registeredWeeks}
                    margin={{
                      top: 5,
                      right: 30,
                      bottom: 5,
                    }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="week" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="usersRegistered" fill="#2196f3" />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>
        </>
      ) : dashboardInfo.status === "error" ? (
        <ErrorMessage messageId={dashboardInfo.error.toString()} />
      ) : (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      )}
    </>
  );
};

export default Dashboard;
