import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLogin } from "../clients/loginClient";
import ErrorMessage from "./ErrorMessage";

const Login = () => {
  const { handleLogin, result } = useLogin();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <Box display="flex" justifyContent="center">
      <Box
        sx={{
          width: 500,
          mt: 10,
          backgroundColor: "#e8e8e8",
          borderRadius: "5px",
        }}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", m: 3 }}>
          <Typography variant="h3" sx={{ mb: 3 }}>
            B+M Admintool
          </Typography>
          <TextField
            label="Name"
            variant="outlined"
            sx={{ width: "25ch" }}
            onChange={(event) => {
              setUsername(event.target.value);
            }}
          />
          <TextField
            label="Passwort"
            type="password"
            variant="outlined"
            sx={{ width: "25ch", m: 2 }}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              handleLogin(username, password);
            }}>
            Login
          </Button>
          {result.status === "loading" ? (
            <Box sx={{ mt: 4 }}>
              <CircularProgress />
            </Box>
          ) : result.status === "error" ? (
            <Box sx={{}}>
              <ErrorMessage messageId={result.messageId} />
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
