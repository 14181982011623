import Navigation, { RouteInfo, routes, routesSetting } from "./components/Navigation";
import { Navigate } from "react-router";
import { useCookies } from "react-cookie";
import Login from "./components/Login";
import { Route, Routes } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import AddEmployee from "./components/AddEmployee";
import EditEmployee from "./components/EditEmployee";
import EditNews from "./components/news/EditNews";
import EditLocation from "./components/EditLocation";
import EditUser from "./components/EditUser";
import AnswerCustomerRequest from "./components/CustomerRequest/CustomerRequest";

function App() {
  const [cookie] = useCookies(["userData"]);

  if (!cookie.userData?.accessToken) {
    return <Login />;
  }
  return (
    <>
      <CssBaseline />
      <Navigation />
      <Routes>
        {routes.map((route: RouteInfo) => (
          <Route path={route.path} key={route.path} element={route.component} />
        ))}
        {routesSetting.map((route: RouteInfo) => (
          <Route path={route.path} key={route.path} element={route.component} />
        ))}
        <Route path={"addEmployee"} key={"addEmployee"} element={<AddEmployee />} />
        <Route path={"editEmployee/:id"} key={"editEmployee"} element={<EditEmployee />} />
        <Route path="/editNews" element={<EditNews />} />
        <Route path="/editNews/:id" key={"editNews"} element={<EditNews />} />
        <Route path="/editLocation/:id" element={<EditLocation />} />
        <Route path="/editUser/:id" element={<EditUser />} />
        <Route path="/answerCustomerRequest/:id" element={<AnswerCustomerRequest />} />

        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </>
  );
}

export default App;
