import { Box, Collapse, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import React, { FC, useState } from "react";
import { NewsInfo, useSendNews } from "../../clients/newsClient";
import { useNavigate } from "react-router-dom";
import FeedIcon from "@mui/icons-material/Feed";
import DiscountIcon from "@mui/icons-material/Discount";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface NewsDataRowProps {
  row: NewsInfo;
}

const NewsDataRow: FC<NewsDataRowProps> = ({ row }: NewsDataRowProps) => {
  const navigate = useNavigate();

  return (
    <>
      <TableRow key={row.inhouseTitle} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell
          component="th"
          scope="row"
          onClick={() => {
            navigate("../editNews/" + row.id.toString());
          }}>
          {row.isAction ? <DiscountIcon color="action" /> : <FeedIcon color="action" />}
        </TableCell>
        <TableCell
          onClick={() => {
            navigate("../editNews/" + row.id.toString());
          }}>
          {row.inhouseTitle}
        </TableCell>
        <TableCell
          onClick={() => {
            navigate("../editNews/" + row.id.toString());
          }}>
          {new Date(row.creationDate).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" })}
        </TableCell>
        <TableCell
          onClick={() => {
            navigate("../editNews/" + row.id.toString());
          }}>
          {new Date(row.startDate).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" })}
        </TableCell>
        <TableCell
          onClick={() => {
            navigate("../editNews/" + row.id.toString());
          }}>
          {new Date(row.expirationDate).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" })}
        </TableCell>
      </TableRow>
    </>
  );
};

export default NewsDataRow;
