interface Location {
  name: string;
  id: string;
}

export const locationList: Location[] = [
  { name: "Berlin", id: "1" },
  { name: "Bielefeld isol", id: "3" },
  { name: "Bremen isol", id: "61" },
  { name: "Bremen-Stuhr", id: "4" },
  { name: "Chemnitz", id: "43" },
  { name: "Erfurt", id: "6" },
  { name: "Ettlingen", id: "7" },
  { name: "Freiburg", id: "9" },
  { name: "Flechtingen", id: "41" },
  { name: "Gelsenkirchen", id: "10" },
  { name: "Gelsenkirchen isol", id: "42" },
  { name: "Gersthofen", id: "11" },
  { name: "GP-COOL SPEED", id: "46" },
  { name: "Güstrow", id: "12" },
  { name: "Hamburg", id: "13" },
  { name: "Hannover", id: "14" },
  { name: "Heilbronn", id: "15" },
  { name: "Heiligenstadt", id: "16" },
  { name: "Ilsfeld isol", id: "63" },
  { name: "Kaufungen", id: "17" },
  { name: "Kiel", id: "47" },
  { name: "Köln", id: "18" },
  { name: "Köln isol", id: "62" },
  { name: "Leipzig", id: "19" },
  { name: "Leipzig isol", id: "20" },
  { name: "Ludwigshafen isol", id: "21" },
  { name: "Mannheim", id: "2" },
  { name: "Mitterskirchen", id: "5" },
  { name: "Möglingen", id: "22" },
  { name: "Mönchengladbach", id: "23" },
  { name: "Mörfelden", id: "24" },
  { name: "München-Nord", id: "26" },
  { name: "München-Ost", id: "25" },
  { name: "München-Süd", id: "44" },
  { name: "Neumünster", id: "27" },
  { name: "Nürnberg", id: "28" },
  { name: "Oderwitz", id: "8" },
  { name: "Osnabrück", id: "29" },
  { name: "Ottersweier", id: "30" },
  { name: "Passau", id: "31" },
  { name: "Radeburg", id: "32" },
  { name: "Regensburg", id: "33" },
  { name: "Reutlingen", id: "34" },
  { name: "Rostock", id: "35" },
  { name: "Saarbrücken", id: "36" },
  { name: "Saarbrücken isol", id: "37" },
  { name: "Salzgitter isol", id: "38" },
  { name: "Viernheim isol", id: "40" },
  { name: "Würzburg", id: "39" },
];
