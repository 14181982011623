import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import { useQueryFetch, useQueryFetchMultiPart } from "./useFetch";

export interface MediaUploadResponse {
  mediaHash: string;
  mediaHashId: string;
}
export interface NewsInfo {
  id: string;
  inhouseTitle: string;
  notificationTitle: string;
  notificationText: string;
  headline: string;
  text: string;
  upload: {
    mediaHashId: string;
    ownerId: string;
    mediaType: string;
    mediaHash: string;
  };
  isActive: boolean;
  isAction: boolean;
  creationDate: string;
  startDate: string;
  expirationDate: string;
  language: string;
  locations: string[];
  uploadUrl: string;
}

export interface ImageUpload {
  file: File;
  mediaType: string;
}

export const useAllNews = (): UseQueryResult<NewsInfo[], Error> => {
  const queryFetch = useQueryFetch<NewsInfo[]>();

  return useQuery<NewsInfo[], Error>(
    ["newsRequestData"],
    () => {
      return queryFetch(process.env.REACT_APP_SERVER + "/backoffice/news");
    },
    { keepPreviousData: false },
  );
};

export const useNews = (newsId: string): UseQueryResult<NewsInfo, Error> => {
  const queryFetch = useQueryFetch<NewsInfo>();
  return useQuery<NewsInfo, Error>(
    ["newsRequestData", newsId],
    () => {
      return queryFetch(process.env.REACT_APP_SERVER + `/backoffice/news/${newsId}`, {
        method: "GET",
      });
    },
    { keepPreviousData: false },
  );
};

export const useAddNews = (): UseMutationResult<NewsInfo, Error, NewsInfo> => {
  const queryFetch = useQueryFetch<NewsInfo>();

  return useMutation<NewsInfo, Error, NewsInfo>((data) => {
    return queryFetch(process.env.REACT_APP_SERVER + `/backoffice/news`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  });
};

export const useUpdateNews = (): UseMutationResult<NewsInfo, Error, NewsInfo> => {
  const queryFetch = useQueryFetch<NewsInfo>();

  return useMutation<NewsInfo, Error, NewsInfo>((data) => {
    return queryFetch(process.env.REACT_APP_SERVER + `/backoffice/news/${data.id}`, {
      method: "PUT",
      body: JSON.stringify({
        inhouseTitle: data.inhouseTitle,
        notificationTitle: data.notificationTitle,
        notificationText: data.notificationText,
        headline: data.headline,
        text: data.text,
        isActive: data.isActive,
        isAction: data.isAction,
        startDate: data.startDate,
        expirationDate: data.expirationDate,
        language: data.language,
        locations: data.locations,
        mediaHashId: data.upload.mediaHashId,
      }),
    });
  });
};

// Image Upload
export const useAddImage = (): UseMutationResult<MediaUploadResponse, Error, ImageUpload> => {
  const queryFetchMultiPart = useQueryFetchMultiPart<MediaUploadResponse>();

  return useMutation<MediaUploadResponse, Error, ImageUpload>((data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("mediaType", data.mediaType);

    return queryFetchMultiPart(process.env.REACT_APP_SERVER + `/backoffice/upload`, {
      method: "POST",
      body: formData,
    });
  });
};

export const useSendNews = (): UseMutationResult<null, Error, string> => {
  const queryFetch = useQueryFetch<null>();

  return useMutation<null, Error, string>((data) => {
    return queryFetch(process.env.REACT_APP_SERVER + `/backoffice/news/send`, {
      method: "POST",
      body: JSON.stringify({ messageId: data }),
    });
  });
};
