import { NewsInfo } from "../../clients/newsClient";

type Order = "asc" | "desc";

export interface HeadCell {
  disablePadding: boolean;
  id: keyof NewsInfo;
  label: string;
  numeric: boolean;
}

export interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof NewsInfo) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

export const headCells: readonly HeadCell[] = [
  {
    id: "isAction",
    numeric: false,
    disablePadding: true,
    label: "Typ",
  },
  {
    id: "inhouseTitle",
    numeric: false,
    disablePadding: true,
    label: "Bezeichnung",
  },
  {
    id: "creationDate",
    numeric: false,
    disablePadding: true,
    label: "erstellt am",
  },
  {
    id: "startDate",
    numeric: false,
    disablePadding: true,
    label: "gültig von",
  },
  {
    id: "expirationDate",
    numeric: false,
    disablePadding: true,
    label: "bis",
  },
];
