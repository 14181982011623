import { useNavigate } from "react-router";
import { useCookies } from "react-cookie";
import { useEffect } from "react";

function Logout() {
  const navigate = useNavigate();
  const [cookie, , removeCookie] = useCookies(["userData"]);
  useEffect(() => {
    if (cookie.userData !== undefined) {
      removeCookie("userData");
    }
    navigate("/login");
  }, [cookie.userData, navigate, removeCookie]);

  return <></>;
}

export default Logout;
