import { useCallback } from "react";
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import { useQueryFetch } from "./useFetch";

export interface RequestMaterial {
  id: string;
  name: string;
  unit: string;
  amount: string;
}

export interface MaterialRequest {
  area: string;
  city: string;
  company: string;
  deliveryTime: string;
  height: number;
  houseNumber: string;
  id: string;
  note: string;
  orderType: string;
  selection: string;
  street: string;
  zipCode: string;
  materialOrder: RequestMaterial[];
}

export type MediaType = "audio" | "image" | "MaterialRequest";

export interface CustomerRequest {
  id: string;
  mediaHash: string;
  materialRequest: MaterialRequest;
  creationDate: string;
  completionDate: string;
  language: string;
  editingEmployee: string;
  customerName: string;
  customerCompany: string;
  employeeNotes: string;
  statusRequest: string;
  phoneCustomer: string;
  userId: string;
  mediaType: MediaType;
}

export interface CustomerEditRequest {
  statusRequest?: string;
  employeeNotes?: string;
  completionDate?: string;
}

export interface Message {
  id?: string;
  text: string;
  createdDate?: Date;
  sentDate?: Date;
  userId?: string;
}

export const useAllCustomerRequestsResult = (): UseQueryResult<CustomerRequest[], Error> => {
  const queryFetch = useQueryFetch<CustomerRequest[]>();

  return useQuery<CustomerRequest[], Error>(
    ["customerRequestData"],
    () => {
      return queryFetch(process.env.REACT_APP_SERVER + "/backoffice/customerRequest");
    },
    { keepPreviousData: false },
  );
};

export const useUpdateCustomerRequest = (
  id: string,
): {
  updateCustomerRequest: (customerRequest: CustomerEditRequest) => void;
} => {
  const queryFetch = useQueryFetch<CustomerEditRequest>();

  const updateCustomerRequest = useCallback(
    (customerRequest: CustomerEditRequest) => {
      queryFetch(process.env.REACT_APP_SERVER + "/backoffice/customerRequest/" + id, {
        method: "PUT",
        body: JSON.stringify(customerRequest),
      });
      /*
      fetcher("https://run.mocky.io/v3/a49b7fd2-127b-4fbf-938b-d081680e0b7a", {
        method: "PUT",
        body: JSON.stringify(customerRequests),
      });*/
    },
    [id, queryFetch],
  );

  return {
    updateCustomerRequest,
  };
};

// Get CustomerRequest
export const useCustomerRequests = (userid: string): UseQueryResult<CustomerRequest[], Error> => {
  const queryFetch = useQueryFetch<CustomerRequest[]>();
  return useQuery<CustomerRequest[], Error>(
    ["customerRequestData", userid],
    () => {
      return queryFetch(process.env.REACT_APP_SERVER + `/backoffice/customerRequest/${userid}`, {
        method: "GET",
      });
    },
    { keepPreviousData: false },
  );
};

export const usePdfDownload = (requestId: string): UseQueryResult<string, Error> => {
  const queryFetch = useQueryFetch<string>();
  return useQuery<string, Error>(["pdfDownloadData", requestId], () => {
    return queryFetch(process.env.REACT_APP_SERVER + `/backoffice/customerRequest/${requestId}/pdf`, {
      method: "GET",
    });
  });
};

// Get Messages
export const useMessages = (userid: string): UseQueryResult<Message[], Error> => {
  const queryFetch = useQueryFetch<Message[]>();
  return useQuery<Message[], Error>(
    ["messageData", userid],
    () => {
      return queryFetch(process.env.REACT_APP_SERVER + `/backoffice/message/${userid}`, {
        method: "GET",
      });
    },
    { keepPreviousData: false },
  );
};

// Add Message
export const useAddMessage = (): UseMutationResult<Message, Error, Message> => {
  const queryFetch = useQueryFetch<Message>();

  return useMutation<Message, Error, Message>((data) => {
    return queryFetch(process.env.REACT_APP_SERVER + `/backoffice/message`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  });
};

// Update Message
export const useUpdateMessage = (messageId: string): UseMutationResult<null, Error, Message> => {
  const queryFetch = useQueryFetch<null>();

  return useMutation<null, Error, Message>((data) => {
    return queryFetch(process.env.REACT_APP_SERVER + `/backoffice/message/${messageId}`, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  });
};

// Send Message
export const useSendMessage = (): UseMutationResult => {
  const queryFetch = useQueryFetch();

  return useMutation((data) => {
    return queryFetch(process.env.REACT_APP_SERVER + `/backoffice/message/${data}/send`, {
      method: "POST",
    });
  });
};

// Delete Message
export const useDeleteMessage = (): UseMutationResult => {
  const queryFetch = useQueryFetch();

  return useMutation((data) => {
    return queryFetch(process.env.REACT_APP_SERVER + `/backoffice/message/${data}`, {
      method: "DELETE",
    });
  });
};
