import "react-h5-audio-player/lib/styles.css";

import { Box, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import {
  Message,
  useAddMessage,
  useCustomerRequests,
  useDeleteMessage,
  useMessages,
  useSendMessage,
  useUpdateMessage,
} from "../../clients/customerRequestClient";
import { useEffect, useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import AudioPlayer from "react-h5-audio-player";
import CalculateIcon from "@mui/icons-material/Calculate";
import CustomerRequestMessageDialog from "./CustomerRequestMessageDialog";
import CustomerRequestPictureDialog from "./CustomerRequestPictureDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { useParams } from "react-router-dom";

const AnswerCustomerRequest = () => {
  const { id } = useParams<{ id: string }>();
  const [sortObject, setSortObject] = useState<any>();
  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const [showPictureDialog, setShowPictureDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");
  const [messageId, setMessageId] = useState("");
  const [toggleRequest, setToggleRequest] = useState(false);
  const customerRequestResult = useCustomerRequests(id ?? "");
  const messageResult = useMessages(id ?? "");
  const addMessage = useAddMessage();
  const updateMessage = useUpdateMessage(messageId ?? "");
  const sendMessage = useSendMessage();
  const deleteMessage = useDeleteMessage();
  useEffect(() => {
    if (customerRequestResult.status === "success" && messageResult.status === "success") {
      const messages: any = messageResult.data.map((item) => {
        return {
          id: item.id,
          text: item.text,
          creationDate: item.createdDate,
          sended: item.sentDate,
          userId: item.userId,
          type: "message",
        };
      });

      // Sort Customer Requests and Employee Answers in the right Timeline
      setSortObject(
        [...messages, ...customerRequestResult.data].sort((a, b) => {
          const da: any = new Date(a.creationDate),
            db: any = new Date(b.creationDate);
          return db - da;
        }),
      );
    }
  }, [customerRequestResult.data, customerRequestResult.status, messageResult.data, messageResult.status, message, messageId]);

  // Open Message Dialog
  const toggleMessageDialog = () => {
    setShowMessageDialog(!showMessageDialog);
  };

  const togglePictureDialog = () => {
    setShowPictureDialog(!showPictureDialog);
  };
  // Save Message from Employee
  const handleSaveMessage = (message: string, id: string) => {
    toggleRequest
      ? addMessage.mutate(
          { text: message, userId: id },
          {
            onSuccess: () => {
              messageResult.refetch();
            },
          },
        )
      : updateMessage.mutate(
          { text: message },
          {
            onSuccess: () => {
              messageResult.refetch();
            },
          },
        );
    setToggleRequest(false);
  };

  // Send Message to Customer
  const handleSendMessage = async (message: string) => {
    if (messageId) {
      // Send new Message after previous save
      sendMessage.mutate(messageId);
    } else {
      // Send new Message before save
      try {
        // Add new Message, wait for Result and send afterwards
        const addMessageResult: Message = await addMessage.mutateAsync({ text: message, userId: id });
        sendMessage.mutate(addMessageResult.id, {
          onSuccess: () => {
            messageResult.refetch();
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleSendExistingMessage = async (existingMessageId: string) => {
    sendMessage.mutate(existingMessageId, {
      onSuccess: () => {
        messageResult.refetch();
      },
    });
  };

  // Delete Message to Customer before sending
  const handleDeleteMessage = (messageId: string) => {
    deleteMessage.mutate(messageId, {
      onSuccess: () => {
        messageResult.refetch();
      },
    });
  };

  return (
    <>
      <Timeline>
        {/* Add Message Button */}
        <IconButton
          disableRipple={true}
          aria-label="back"
          onClick={() => {
            setMessage("");
            setToggleRequest(true);
            toggleMessageDialog();
          }}>
          <Tooltip title="Antworten">
            <AddCircleIcon sx={{ color: "#1976D2", fontSize: 55 }} />
          </Tooltip>
        </IconButton>

        {sortObject?.map((item: any) => {
          if (item.type === "message") {
            // Employee Answer Message
            return (
              <TimelineItem key={item.creationDate} position="right">
                <TimelineOppositeContent color="text.secondary">
                  {new Date(item.creationDate).toLocaleString("de-DE", { dateStyle: "medium", timeStyle: "short" })}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="secondary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Box>
                    <Paper sx={{ padding: 1 }}>
                      <Typography>{item.text}</Typography>
                      {item.sended ? (
                        <Typography sx={{ fontSize: 13, height: 20 }}>
                          {new Date(item.sended).toLocaleString("de-DE", { dateStyle: "medium", timeStyle: "short" })}
                        </Typography>
                      ) : (
                        <Box display="flex" flex="1" justifyContent="space-between">
                          {/* Send Message */}
                          <IconButton disableRipple={true} aria-label="back" onClick={() => handleSendExistingMessage(item.id)}>
                            <Tooltip title="Versenden">
                              <SendIcon sx={{ color: "primary", fontSize: 20 }} />
                            </Tooltip>
                          </IconButton>
                          <Box>
                            {/* Edit Message */}
                            <IconButton
                              disableRipple={true}
                              aria-label="back"
                              onClick={() => {
                                setMessage(item.text);
                                setMessageId(item.id);
                                toggleMessageDialog();
                              }}>
                              <Tooltip title="Bearbeiten">
                                <EditIcon sx={{ color: "primary", fontSize: 20 }} />
                              </Tooltip>
                            </IconButton>
                            {/* Delete Message  */}
                            <IconButton disableRipple={true} aria-label="back" onClick={() => handleDeleteMessage(item.id)}>
                              <Tooltip title="Löschen">
                                <DeleteIcon sx={{ color: "primary", fontSize: 20 }} />
                              </Tooltip>
                            </IconButton>
                          </Box>
                        </Box>
                      )}
                    </Paper>
                  </Box>
                </TimelineContent>
              </TimelineItem>
            );
          } else {
            // Customer Request Message
            return (
              <TimelineItem key={item.creationDate} position="left">
                <TimelineOppositeContent color="text.secondary">
                  {new Date(item.creationDate).toLocaleString("de-DE", { dateStyle: "medium", timeStyle: "short" })}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Box>
                    {/* Customer Material Request */}
                    {item.mediaType === "MaterialRequest" ? (
                      <Paper sx={{ textAlign: "left", padding: 2 }}>
                        <Box display="flex" flex="1" sx={{ marginBottom: 1 }}>
                          <CalculateIcon sx={{ marginTop: 0.5 }} />
                          <Typography sx={{ fontSize: 18 }}>
                            {item.materialRequest.area} m<sup>2</sup>
                          </Typography>
                        </Box>
                        <Typography>{item.materialRequest.selection}</Typography>
                      </Paper>
                    ) : // Customer Audio Request
                    item.mediaType === "audio" ? (
                      <Paper sx={{ textAlign: "center" }}>
                        <AudioPlayer src={item.mediaUrl} layout="horizontal" customAdditionalControls={[]} showDownloadProgress={true} />
                      </Paper>
                    ) : (
                      // Customer Photo Request
                      <Paper sx={{ textAlign: "center" }}>
                        <img
                          src={`${item.mediaUrl}?w=164&h=164&fit=crop&auto=format`}
                          srcSet={`${item.mediaUrl}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          alt={item.mediaType}
                          style={{ width: "50%", marginTop: 6 }}
                          onClick={() => {
                            setMediaUrl(item.mediaUrl);
                            togglePictureDialog();
                          }}
                          loading="lazy"
                        />
                      </Paper>
                    )}
                  </Box>
                </TimelineContent>
              </TimelineItem>
            );
          }
        })}
      </Timeline>
      {/* Message Dialog */}
      <CustomerRequestMessageDialog
        isOpen={showMessageDialog}
        onSave={(item) => {
          handleSaveMessage(item, id ? id : "");
        }}
        onSend={(item) => {
          handleSendMessage(item);
        }}
        onChange={toggleMessageDialog}
        text={message}
      />
      <CustomerRequestPictureDialog isOpen={showPictureDialog} onClose={togglePictureDialog} mediaUrl={mediaUrl} />
    </>
  );
};

export default AnswerCustomerRequest;
