import { useCallback } from "react";
import { useCookies } from "react-cookie";
import { UserData } from "./loginClient";

interface ResultInitial {
  status: "initial";
}

interface ResultLoading {
  status: "loading";
}

export interface ResultLoaded<T> {
  status: "loaded";
  payload: T;
}

interface ResultError {
  status: "error";
  responseErrorCode: number | null;
  messageId: string;
}

export type Result<T> = ResultInitial | ResultLoading | ResultLoaded<T> | ResultError;

export const useQueryFetch = <T,>(): ((input: RequestInfo, init?: RequestInit) => Promise<T>) => {
  const [cookie, setCookie, removeCookie] = useCookies<"userData", { userData: UserData }>(["userData"]);
  return useCallback(
    (input: RequestInfo, init?: RequestInit) =>
      fetch(input, {
        ...init,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `${cookie.userData?.accessToken}`,
          ...init?.headers,
        },
      }).then((response) => {
        if (response.ok) {
          const res = response.clone();
          return response.json().catch(() => {
            return res.text();
          });
        } else if (response.status === 401) {
          return fetch(process.env.REACT_APP_SERVER + "/backoffice/session/refresh", {
            method: "GET",
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: `${cookie.userData?.refreshToken}`,
            },
          }).then(async (response) => {
            if (response.ok) {
              const responseToJson = await response.json();
              setCookie("userData", { ...responseToJson });
              return fetch(input, {
                ...init,
                headers: {
                  "Content-Type": "application/json;charset=UTF-8",
                  Authorization: `${responseToJson.accessToken}`,
                  ...init?.headers,
                },
              }).then((response) => {
                if (response.ok) {
                  const res = response.clone();
                  return response.json().catch(() => {
                    return res.text();
                  });
                } else {
                  throw new Error();
                }
              });
            } else {
              removeCookie("userData");
              throw new Error();
            }
          });
        } else {
          throw new Error();
        }
      }),
    [cookie.userData?.accessToken, cookie.userData?.refreshToken, removeCookie, setCookie],
  );
};

export const useQueryFetchMultiPart = <T,>(): ((input: RequestInfo, init?: RequestInit) => Promise<T>) => {
  const [cookie, setCookie, removeCookie] = useCookies<"userData", { userData: UserData }>(["userData"]);

  return useCallback(
    (input: RequestInfo, init?: RequestInit) =>
      fetch(input, {
        ...init,
        headers: {
          Authorization: `${cookie.userData.accessToken}`,
          ...init?.headers,
        },
      }).then((response) => {
        if (response.ok) {
          if (response.headers.get("Content-Type")?.includes("text/plain")) {
            return response.text().catch(() => {
              return null;
            });
          }
          return response.json().catch(() => {
            return null;
          });
        } else {
          if (response.status === 401) {
            fetch(process.env.REACT_APP_SERVER + "/backoffice/session/refresh", {
              headers: {
                "Content-Type": "application/json",
                Authorization: `${cookie.userData.refreshToken}`,
              },
            }).then((response) => {
              if (response.ok) {
                const refreshData = response.json();
                setCookie("userData", refreshData);
              } else {
                removeCookie("userData");
              }
            });
          } else {
            throw new Error();
          }
        }
      }),
    [cookie.userData.accessToken, cookie.userData.refreshToken, removeCookie, setCookie],
  );
};
