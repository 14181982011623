interface Language {
  value: string;
  label: string;
}

export const allLanguages: Language[] = [
  {
    value: "ba",
    label: "Bosnisch",
  },
  {
    value: "me",
    label: "Montenegrinisch",
  },
  {
    value: "de",
    label: "Deutsch",
  },
  {
    value: "en",
    label: "Englisch",
  },
  {
    value: "fr",
    label: "Französisch",
  },
  {
    value: "hr",
    label: "Kroatisch",
  },
  {
    value: "hu",
    label: "Ungarisch",
  },
  {
    value: "pl",
    label: "Polnisch",
  },
  {
    value: "ro",
    label: "Rumänisch",
  },
  {
    value: "ru",
    label: "Russisch",
  },
  {
    value: "sr",
    label: "Serbisch",
  },
  {
    value: "tr",
    label: "Türkisch",
  },
  {
    value: "sq",
    label: "Albanisch",
  },
];
