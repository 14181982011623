import { Box, Button, DialogActions, DialogContent, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";

import { FC, useEffect, useState } from "react";

interface CustomerRequestMessageDialogProps {
  isOpen: boolean;
  text: string;
  onSave: (message: string) => void;
  onSend: (message: string) => void;
  onChange: () => void;
}

const CustomerRequestMessageDialog: FC<CustomerRequestMessageDialogProps> = ({
  isOpen,
  text,
  onSave,
  onSend,
  onChange,
}: CustomerRequestMessageDialogProps) => {
  const [values, setValues] = useState({
    name: "",
  });

  useEffect(() => {
    setValues({ name: text });
  }, [text, onSave]);

  const handleChange = (name: string) => (event: { target: { value: any } }) => {
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={isOpen}>
      {/* Dialog Input */}
      <DialogContent>
        <TextField
          value={values.name}
          InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
          fullWidth
          multiline
          autoFocus
          margin="dense"
          label="Nachricht an den Kunden"
          variant="standard"
          onChange={handleChange("name")}
          onFocus={function (e) {
            const val = e.target.value;
            e.target.value = "";
            e.target.value = val;
          }}
          FormHelperTextProps={{ style: { fontSize: 11, textAlign: "right" } }}
          helperText={values.name.length > 0 ? values.name.length : "0"}
        />
      </DialogContent>
      {/* Dialog Buttons */}
      <DialogActions>
        <Box display="flex" flex="1" justifyContent="space-between">
          <Button
            onClick={() => {
              onChange();
            }}>
            Abbrechen
          </Button>
          <Box>
            <Button
              onClick={() => {
                onSave(values.name);
                setValues({ name: "" });
                onChange();
              }}>
              Speichern
            </Button>
            <Button
              onClick={() => {
                onSend(values.name);
                setValues({ name: "" });
                onChange();
              }}>
              Senden
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerRequestMessageDialog;
