import { Box, Checkbox, Container, IconButton, ListItemText, MenuItem, TextField, Tooltip } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Employee, EmployeeInfo, useEmployee, useUpdateEmployee } from "../clients/teamClient";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { allLanguages } from "../assets/data/Languages";
import { CleaningServices } from "@mui/icons-material";

const EditEmployee: FC = () => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    languages: [] as unknown as string[],
  });
  const [language, setLanguage] = useState(formState.languages as unknown as string[]);
  const [passwordRepeat, setPasswordRepeat] = useState("");

  const { register, reset, handleSubmit, setValue, getValues } = useForm<EmployeeInfo>();
  const { id } = useParams<{ id: string }>();
  const employeeResult = useEmployee(id ?? "");
  const updateEmployee = useUpdateEmployee(id ?? "");
  const passwordState = getValues("password");

  useEffect(() => {
    if (employeeResult.status === "success") {
      reset(employeeResult.data);
      setLanguage(employeeResult.data.language);
    }
  }, [employeeResult.data, employeeResult.status, reset]);

  const handleFieldChange = (event: any) => {
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
    }));
    setLanguage(event.target.value);
    setValue("language", event.target.value);
  };

  const handlePasswordRepeat = (event: any) => {
    setPasswordRepeat(event.target.value);
  };

  return (
    <Box flexGrow="1" sx={{ mt: 5 }}>
      <Container maxWidth="lg">
        <form
          onSubmit={handleSubmit((data) => {
            if (passwordState !== "" && passwordState === passwordRepeat) {
              updateEmployee.mutate(data);
            } else if (data.password === passwordRepeat) {
              updateEmployee.mutate({
                email: data.email,
                firstname: data.firstname,
                lastname: data.lastname,
                language: data.language,
                id: data.id,
              });
            }
          })}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton aria-label="back" onClick={() => navigate(`/team`)}>
              <Tooltip title="Verwerfen">
                <ArrowBackRoundedIcon color="primary" fontSize="large" />
              </Tooltip>
            </IconButton>
            <IconButton aria-label="save" type="submit">
              <Tooltip title="Speichern">
                <SaveIcon color="primary" fontSize="large" />
              </Tooltip>
            </IconButton>
          </Box>
          <Box display="flex" flex="1">
            <Box flex="1">
              <TextField
                {...register("firstname", { required: true })}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                label="Vorname"
                variant="standard"
              />
            </Box>
            <Box flex="1" sx={{ ml: 2 }}>
              <TextField
                {...register("lastname", { required: true })}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                label="Name"
                variant="standard"
              />
            </Box>
          </Box>
          <Box display="flex" flex="1">
            <Box flex="1">
              <TextField
                {...register("email", { required: true })}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                label="Email"
                variant="standard"
              />
            </Box>
          </Box>
          <Box display="flex" flex="1" justifyContent="space-between" flexWrap="wrap">
            <Box flex="1">
              <TextField
                {...register("language")}
                value={language}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                select
                name="languages"
                id="languages"
                variant="standard"
                label="Sprache"
                SelectProps={{ multiple: true, renderValue: (selected: any) => selected.join(", "), onChange: handleFieldChange }}>
                {allLanguages.map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    <Checkbox checked={language.indexOf(option.value) > -1} />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
          <Box display="flex" flex="1" justifyContent="space-between" flexWrap="wrap">
            <Box flex="1">
              <TextField
                {...register("password")}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                label="Passwort"
                variant="standard"
                type="password"
              />
            </Box>
            <Box flex="1" sx={{ ml: 2 }}>
              <TextField
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                label="Passwort Wiederholen"
                variant="standard"
                type="password"
                onChange={handlePasswordRepeat}
              />
            </Box>
          </Box>
        </form>
      </Container>
    </Box>
  );
};

export default EditEmployee;
