import { TextField, Typography } from "@mui/material";

import { FC, useEffect, useState } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Container } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ReportInfo, useReport } from "../../clients/teamClient";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string | string[] }, b: { [key in Key]: number | string | string[] }) => number {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const mediaTypeToString = (mediaType: string) => {
  switch (mediaType) {
    case "audio":
      return "Sprachnachrichten";
    case "image":
      return "Bilder";
    case "MaterialRequest":
      return "Materialbedarf";
  }
};

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "mediaType",
    numeric: false,
    disablePadding: true,
    label: "Typ",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: true,
    label: "Anzahl",
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow
        style={{
          whiteSpace: "nowrap",
          backgroundColor: grey[400],
        }}>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.numeric ? "right" : "left"} padding={"normal"}>
            <Typography variant="h5">{headCell.label}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const Report: FC = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const reportResult = useReport(selectedDate);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tableData, setTableData] = useState<ReportInfo[]>([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (reportResult.status === "success") {
      setTableData(
        reportResult.data.filter((row) => row.editedCustomerRequests.map((request) => Number(request.number)).reduce((a, b) => a + b, 0)),
      );
    }
  }, [reportResult.data]);

  useEffect(() => {
    reportResult.refetch({});
  }, [selectedDate]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;
  return (
    <Container maxWidth="lg" sx={{ marginTop: 4 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          views={["year", "month"]}
          label="Jahr und Monat"
          minDate={new Date("2021-01-01")}
          maxDate={new Date("2100-01-01")}
          value={selectedDate}
          onChange={(newValue) => {
            if (newValue) {
              setSelectedDate(newValue);
            }
          }}
          renderInput={(params) => <TextField {...params} helperText={null} />}
        />
      </LocalizationProvider>
      <Box sx={{ width: "100%", float: "left" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={"small"}>
              <EnhancedTableHead />
              <TableBody>
                {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const addStr = (str: string, index: number, stringToAdd: string) => {
                    return str?.substring(0, index) + stringToAdd + str?.substring(index, str.length);
                  };
                  return (
                    <>
                      {row.editedCustomerRequests.map((request, i) => (
                        <TableRow key={i}>
                          {i === 0 ? (
                            <TableCell component="th" id={labelId} scope="row">
                              <Typography variant="h5">{row.name}</Typography>
                            </TableCell>
                          ) : (
                            <TableCell />
                          )}

                          <TableCell>
                            <Typography>{mediaTypeToString(request.mediaType)}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{request.number}</Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                      {row.editedCustomerRequests.length > 0 ? (
                        <TableRow key={row.name + "requests"}>
                          <TableCell></TableCell>
                          <TableCell>
                            <Typography variant="h5">{"Gesamt:"}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h5">
                              {row.editedCustomerRequests.map((request) => Number(request.number)).reduce((a, b) => a + b, 0)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Container>
  );
};

export default Report;
