import {
  Box,
  Container,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  NativeSelect,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Tooltip,
} from "@mui/material";
import { CustomerRequest, MaterialRequest, useAllCustomerRequestsResult } from "../../clients/customerRequestClient";
import React, { FC, useEffect, useMemo, useState } from "react";

import CustomerRequestRow from "./CustomerRequestRow";
import ErrorMessage from "../ErrorMessage";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { grey } from "@mui/material/colors";
import jwt_decode from "jwt-decode";
import { useCookies } from "react-cookie";
import { visuallyHidden } from "@mui/utils";

export interface JwtPayload {
  exp?: number;
  iat?: number;
  method: string;
  sub: string;
}

export interface ColumnDisplayFilter {
  Typ: string;
  Status: string;
  Sprache: string;
  Firma: string;
  Kundenname: string;
  Empfangen: string;
  Bearbeitet: string;
  Beschreibung: string;
}

interface Language {
  id: string;
  name: string;
}

const columns = ["Typ", "Status", "Sprache", "Firma", "Kundenname", "Empfangen", "Bearbeitet", "Beschreibung"];

const languages: Language[] = [
  {
    id: "de",
    name: "Deutsch",
  },
  {
    id: "en",
    name: "Englisch",
  },
  {
    id: "esp",
    name: "Spanisch",
  },
  {
    id: "nl",
    name: "Niederländisch",
  },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string | MaterialRequest }, b: { [key in Key]: number | string | MaterialRequest }) => number {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof CustomerRequest) => void;
  order: Order;
  orderBy: string;
  displayData: ColumnDisplayFilter;
}

type Filter = "Typ" | "Status" | "Sprache" | "Kundenname" | "Empfangen" | "Bearbeitet" | "Beschreibung" | "Firma";

interface HeadCell {
  disablePadding: boolean;
  id: keyof CustomerRequest;
  label: Filter;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "mediaType",
    numeric: false,
    disablePadding: true,
    label: "Typ",
  },
  {
    id: "statusRequest",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "language",
    numeric: false,
    disablePadding: true,
    label: "Sprache",
  },
  {
    id: "customerCompany",
    numeric: false,
    disablePadding: true,
    label: "Firma",
  },
  {
    id: "customerName",
    numeric: false,
    disablePadding: true,
    label: "Kundenname",
  },
  {
    id: "creationDate",
    numeric: false,
    disablePadding: true,
    label: "Empfangen",
  },
  {
    id: "completionDate",
    numeric: false,
    disablePadding: true,
    label: "Bearbeitet",
  },
  {
    id: "materialRequest",
    numeric: false,
    disablePadding: true,
    label: "Beschreibung",
  },
];

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, displayData } = props;
  const createSortHandler = (property: keyof CustomerRequest) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow
        style={{
          whiteSpace: "nowrap",
          backgroundColor: grey[400],
        }}>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ display: displayData[headCell.label] }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}>
              <Typography variant="h5">{headCell.label}</Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="center" key="Ausklappen">
          <Typography variant="h5"></Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const CustomerRequests: FC = () => {
  const [cookie] = useCookies(["userData"]);
  const jwtData = useMemo<JwtPayload>(() => jwt_decode(cookie.userData.accessToken), [cookie]);
  const allCustomerRequestsResult = useAllCustomerRequestsResult();
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [tableData, setTableData] = useState<CustomerRequest[]>([]);
  const [onlyUnedited, setOnlyUnedited] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    "Typ",
    "Status",
    "Firma",
    "Kundenname",
    "Empfangen",
    "Bearbeitet",
    "Beschreibung",
  ]);
  const [orderBy, setOrderBy] = React.useState<keyof CustomerRequest>("creationDate");
  const [order, setOrder] = React.useState<Order>("desc");

  const sortedTableData = useMemo(() => {
    let updatedTableData = tableData ? [...tableData] : [];
    if (onlyUnedited) {
      updatedTableData = updatedTableData.filter((row) => row.statusRequest === "unedited" || row.statusRequest === "inProgress");
    }
    if (selectedLanguage) {
      updatedTableData = updatedTableData.filter((row) => row.language === selectedLanguage);
    }
    return updatedTableData;
  }, [onlyUnedited, selectedLanguage, tableData]);

  const [displayData, setDisplayData] = useState<ColumnDisplayFilter>({
    Typ: "",
    Status: "",
    Sprache: "none",
    Firma: "",
    Kundenname: "",
    Empfangen: "",
    Bearbeitet: "",
    Beschreibung: "",
  });

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof CustomerRequest) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeFilter = (event: SelectChangeEvent<typeof selectedColumns>) => {
    const {
      target: { value },
    } = event;
    setSelectedColumns(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  useEffect(() => {
    const updatedDisplayData: ColumnDisplayFilter = {
      Typ: "none",
      Status: "none",
      Sprache: "none",
      Firma: "none",
      Kundenname: "none",
      Empfangen: "none",
      Bearbeitet: "none",
      Beschreibung: "none",
    };

    selectedColumns.forEach((column) => {
      switch (column) {
        case "Typ":
          updatedDisplayData.Typ = "";
          break;
        case "Status":
          updatedDisplayData.Status = "";
          break;
        case "Sprache":
          updatedDisplayData.Sprache = "";
          break;
        case "Firma":
          updatedDisplayData.Firma = "";
          break;
        case "Kundenname":
          updatedDisplayData.Kundenname = "";
          break;
        case "Empfangen":
          updatedDisplayData.Empfangen = "";
          break;
        case "Bearbeitet":
          updatedDisplayData.Bearbeitet = "";
          break;
        case "Beschreibung":
          updatedDisplayData.Beschreibung = "";
          break;
        default:
          break;
      }
    });
    setDisplayData(updatedDisplayData);
  }, [selectedColumns]);

  useEffect(() => {
    if (allCustomerRequestsResult.isSuccess) {
      setTableData(allCustomerRequestsResult.data);
    } else {
      setTableData([]);
    }
  }, [allCustomerRequestsResult.isSuccess, allCustomerRequestsResult.data]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  return (
    <Container maxWidth="lg" sx={{ mt: 3 }}>
      <Box sx={{ mt: 2, p: 1, minWidth: 600 }} flexGrow="1">
        <Box display="flex" justifyContent="center" sx={{ mb: 2 }} width="100%" flexWrap="wrap">
          <Box flex="2" sx={{ mr: 2 }}>
            <InputLabel variant="standard">Sprache</InputLabel>
            <FormControl variant="outlined" fullWidth>
              <NativeSelect
                style={{ width: "100%" }}
                sx={{ mb: 2 }}
                value={selectedLanguage}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  setSelectedLanguage(event.target.value);
                }}
                name="language">
                <option value="">Alle Sprachen</option>
                {languages.map((language) => (
                  <option key={language.id} value={language.id}>
                    {language.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Box>
          <Box flex="2" sx={{ mr: 2 }}>
            <InputLabel id="demo-multiple-checkbox-label">Spalten</InputLabel>
            <FormControl variant="standard" fullWidth>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                style={{ width: "100%" }}
                sx={{ mb: 2, overflow: "hidden" }}
                autoWidth={true}
                value={selectedColumns}
                onChange={handleChangeFilter}
                input={<Input />}
                renderValue={(selected) => selected.join(", ")}>
                {columns.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={selectedColumns.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box flex="1" sx={{ mr: 2 }} alignSelf="center" flexWrap="nowrap">
            <FormControlLabel
              control={
                <Switch
                  checked={onlyUnedited}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setOnlyUnedited(event.target.checked);
                  }}
                />
              }
              label={
                <Typography noWrap variant="body1">
                  nur unbearbeitete
                </Typography>
              }
            />
          </Box>
          <Box flex="1" sx={{ mr: 2 }} alignSelf="center">
            <IconButton aria-label="delete" size="large" color="primary" onClick={() => allCustomerRequestsResult.refetch()}>
              <Tooltip title="Aktualisieren">
                <RefreshOutlinedIcon fontSize="large" />
              </Tooltip>
            </IconButton>
          </Box>
        </Box>
        {allCustomerRequestsResult.status === "success" ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="medium">
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} displayData={displayData} />
              <TableBody>
                {stableSort(sortedTableData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <CustomerRequestRow
                        key={row.id}
                        row={row}
                        requestId={row.id}
                        employeeId={jwtData.sub}
                        filter={displayData}
                        onRowChange={(updatedRow) => {
                          setTableData((prevTable) => {
                            const updatedTableData = [...prevTable];
                            const index = tableData.indexOf(row);
                            updatedTableData[index] = updatedRow;

                            const updatedCustomerPayload = { ...allCustomerRequestsResult };
                            updatedCustomerPayload.data = updatedTableData;

                            return updatedTableData;
                          });
                        }}
                      />
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={allCustomerRequestsResult.data ? allCustomerRequestsResult.data.length : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        ) : allCustomerRequestsResult.status === "error" ? (
          <ErrorMessage messageId={allCustomerRequestsResult.error.toString()} />
        ) : (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        )}
      </Box>
    </Container>
  );
};

export default CustomerRequests;
