import { Box, Button, DialogActions, DialogContent, ImageListItem } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import { FC } from "react";

interface CustomerRequestPictureDialogProps {
  isOpen: boolean;
  mediaUrl: string;
  onClose: () => void;
}

const CustomerRequestPictureDialog: FC<CustomerRequestPictureDialogProps> = ({ isOpen, mediaUrl, onClose }) => {
  return (
    <Dialog maxWidth="sm" fullWidth open={isOpen}>
      {/* Dialog Input */}
      <DialogContent>
        <ImageListItem key={mediaUrl} sx={{ textAlign: "center" }}>
          <img src={`${mediaUrl}`} srcSet={`${mediaUrl}`} alt={mediaUrl} style={{ marginTop: 8 }} loading="lazy" />
        </ImageListItem>
      </DialogContent>
      {/* Dialog Buttons */}
      <DialogActions>
        <Box display="flex" flex="1" justifyContent="right">
          <Button
            onClick={() => {
              onClose();
            }}>
            Schließen
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerRequestPictureDialog;
