import {
  Alert,
  AlertColor,
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NewsInfo, useAddImage, useAddNews, useNews, useSendNews, useUpdateNews } from "../../clients/newsClient";
import { Controller, useForm } from "react-hook-form";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { locationList } from "../../assets/data/LocationList";
import { allLanguages } from "../../assets/data/Languages";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";

import defaultImage from "../../assets/img/default-image.png";

const EditNews = () => {
  const [location, setLocation] = useState<string[]>([]);
  const [language, setLanguage] = useState("de");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertType, setAlertType] = useState<AlertColor>();
  const [image, setImage] = useState<File>();
  const { register, reset, setValue, control, handleSubmit } = useForm<NewsInfo>();
  const { id } = useParams<{ id: string }>();
  const newsResult = useNews(id ?? "");
  const addImage = useAddImage();
  const navigate = useNavigate();
  const addNews = useAddNews();
  const updateNews = useUpdateNews();
  const isCreateMode = !id;

  useEffect(() => {
    if (newsResult.status === "success" && !isCreateMode) {
      reset(newsResult.data);
      const idList = locationList
        .filter((loc) => {
          return newsResult.data.locations.includes(loc.id);
        })
        .map((loc) => {
          return loc.name;
        });
      setLocation(idList || []);
      setLanguage(newsResult.data.language);
    }
    if (isCreateMode) {
      reset({ locations: [] });
    }
  }, [newsResult.data, newsResult.status, reset, isCreateMode]);

  useEffect(() => {
    if (addNews.isSuccess) {
      navigate(`/editNews/${addNews.data.id}`);
    }
  }, [addNews.data, addNews.isSuccess, navigate]);

  const handleChangeLocation = (event: SelectChangeEvent<typeof location>) => {
    const {
      target: { value },
    } = event;
    setLocation(typeof value === "string" ? value.split(",") : value);
    setValue("locations", typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeLanguage = (event: any) => {
    setLanguage(event.target.value);
    setValue("language", language);
  };

  const onImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setImage(event.target.files[0]);
    }
  };

  const handleUpdateSubmit = async (data: any) => {
    const idList = locationList
      .filter((loc) => {
        return location.includes(loc.name);
      })
      .map((loc) => {
        return loc.id;
      });
    if (image !== undefined) {
      await addImage.mutate(
        { file: image, mediaType: image.type.includes("image") ? "image" : "pdf" },
        {
          onSuccess: (res) => {
            data.upload.mediaHashId = res.mediaHashId;
            if (isCreateMode) {
              data.language = language;
              addNews.mutate({ ...data, locations: idList });
              navigate("/editNews/:id");
            } else {
              data.language = language;
              updateNews.mutate({ ...data, locations: idList });
            }
          },
        },
      );
    } else if (isCreateMode) {
      data.language = language;
      addNews.mutate({ ...data, locations: idList });
      navigate("/editNews/:id");
    } else {
      data.language = language;
      updateNews.mutate({ ...data, locations: idList });
    }
    setOpenSnackbar(true);
  };

  const handleCreateSubmit = (data: any) => {
    const idList = locationList
      .filter((loc) => {
        return location.includes(loc.name);
      })
      .map((loc) => {
        return loc.id;
      });
    console.log(idList);
    if (image !== undefined) {
      addImage.mutate(
        { file: image, mediaType: image.type.includes("image") ? "image" : "pdf" },
        {
          onSuccess: (res) => {
            const mediaHashId = res.mediaHashId;
            data.mediaHashId = mediaHashId;
            data.language = language;
            addNews.mutate(
              { ...data, locations: idList },
              {
                onSuccess: (response) => {
                  navigate("/editNews/" + response.id);
                },
              },
            );
            setAlertType("success");
          },
        },
      );
    }
  };

  const sendNews = useSendNews();

  const sendCurrentNews = () => {
    if (id) {
      sendNews.mutate(id);
    }
  };

  return (
    <Box flexGrow="1" sx={{ mt: 5 }}>
      <Container>
        <form onSubmit={handleSubmit(isCreateMode ? handleCreateSubmit : handleUpdateSubmit)}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton aria-label="back" type="button" onClick={() => navigate("/news")}>
              <ArrowBackIcon color="primary" fontSize="large" />
            </IconButton>
            <IconButton aria-label="save" type="submit">
              <SaveIcon color="primary" fontSize="large" />
            </IconButton>
            <Tooltip title={"News absenden"}>
              <IconButton onClick={sendCurrentNews}>
                <SendIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box display="flex" flex="2">
            <Box flex="3">
              <TextField
                {...register("inhouseTitle", { required: true })}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                label="Bezeichnung (intern)"
                variant="standard"
              />
            </Box>
            <Box display="flex" flex="1" justifyContent="flex-end" sx={{ ml: 2 }}>
              <FormControlLabel
                label="Aktiv"
                control={
                  <Controller
                    name="isActive"
                    defaultValue={false}
                    control={control}
                    render={({ field }) => <Switch onChange={(e) => field.onChange(e.target.checked)} checked={field.value ?? false} />}
                  />
                }
              />
              <FormControlLabel
                label="News"
                control={
                  <Controller
                    name="isAction"
                    defaultValue={false}
                    control={control}
                    render={({ field }) => <Switch onChange={(e) => field.onChange(e.target.checked)} checked={field.value ?? false} />}
                  />
                }
              />
            </Box>
          </Box>
          <Box display="flex" flex="1" justifyContent="space-between" flexWrap="wrap">
            <Box flex="1">
              <TextField
                {...register("notificationTitle", { required: true })}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                label="Notification Titel"
                variant="standard"
              />
            </Box>
            <Box flex="1" sx={{ ml: 2 }}>
              <TextField
                {...register("notificationText", { required: true })}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                label="Notification Text"
                variant="standard"
              />
            </Box>
          </Box>
          <Box display="flex" flex="1" justifyContent="center" flexWrap="wrap">
            <Box flex="1">
              <Controller
                control={control}
                name="startDate"
                defaultValue={new Date().toISOString()}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    inputFormat="dd.MM.yyyy"
                    mask={"__.__.____"}
                    label={"Verfügbarkeit von"}
                    onChange={(date) => {
                      if (date) {
                        const newDate = new Date(date);
                        if (newDate instanceof Date && !isNaN(newDate.valueOf())) {
                          onChange(newDate.toISOString());
                          return;
                        }
                        onChange("invalid");
                      } else {
                        onChange("");
                      }
                    }}
                    value={value}
                    renderInput={(params) => (
                      <TextField
                        InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                        variant="standard"
                        fullWidth
                        size="small"
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Box>
            <Box flex="1" sx={{ ml: 2 }}>
              <Controller
                control={control}
                name="expirationDate"
                defaultValue={new Date().toISOString()}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    inputFormat="dd.MM.yyyy"
                    mask={"__.__.____"}
                    label={"Verfügbarkeit bis"}
                    onChange={(date) => {
                      if (date) {
                        const newDate = new Date(date);
                        if (newDate instanceof Date && !isNaN(newDate.valueOf())) {
                          onChange(newDate.toISOString());
                          return;
                        }
                        onChange("invalid");
                      } else {
                        onChange("");
                      }
                    }}
                    value={value}
                    renderInput={(params) => (
                      <TextField
                        InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                        variant="standard"
                        fullWidth
                        size="small"
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Box>
          </Box>
          <Box display="flex" flex="1">
            <Box flex="1">
              <FormControl variant="standard" fullWidth>
                <InputLabel>{location.length > 0 ? "Filialen" : "Alle Standorte gesetzt"}</InputLabel>
                <Select multiple value={location} onChange={handleChangeLocation} renderValue={(selected) => selected.join(", ")}>
                  {locationList.map((locationEntry) => (
                    <MenuItem key={locationEntry.id} value={locationEntry.name}>
                      <Checkbox checked={location?.indexOf(locationEntry.name) > -1} />
                      <ListItemText primary={locationEntry.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box display="flex" flex="1" justifyContent="space-between" flexWrap="wrap" sx={{ mt: 4 }}>
            <Box flex="2" sx={{ mt: 1 }}>
              <TextField
                {...register("headline", { required: true })}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                label="Überschrift"
                variant="standard"
              />
            </Box>
            <Box flex="1" sx={{ ml: 2 }}>
              <TextField
                {...register("language")}
                value={language}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                select
                name="languages"
                id="languages"
                variant="standard"
                label="Sprache"
                SelectProps={{ onChange: handleChangeLanguage }}>
                {allLanguages.map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
          <Box display="flex" flex="1">
            <Box flex="1">
              <TextField
                {...register("text", { required: true })}
                InputLabelProps={{ shrink: true, focused: false, variant: "standard" }}
                fullWidth
                multiline
                rows={12}
                label="Text"
                variant="standard"
              />
            </Box>
          </Box>
          <Box display="flex" flex="1">
            <Box flex="1" sx={{ mb: 3 }}>
              {image ? (
                <img src={URL.createObjectURL(image)} height="600" width="600" />
              ) : newsResult.data?.uploadUrl ? (
                <img src={newsResult.data?.uploadUrl} height="600" width="600" />
              ) : (
                <img src={defaultImage} height="600" width="600" />
              )}
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() => {
                  setImage(undefined);
                }}>
                Bild entfernen
              </Button>
            </Box>
            <Box flex="1" sx={{ ml: 3 }}>
              <input type="file" onChange={onImageChange} />
            </Box>
          </Box>
        </form>
      </Container>
      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity={alertType}>
          {alertType === "success" ? "Gespeichert!" : "Geändert!"}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditNews;
