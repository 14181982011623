import { Box, Typography } from "@mui/material";
import React, { FC } from "react";

interface ErrorMessageProps {
  messageId?: string;
}

// Mit Team besprechen bzgl. intl
const ErrorMessage: FC<ErrorMessageProps> = ({ messageId = "General.error.default" }) => {
  return (
    <Box sx={{ m: 3 }} style={{ textAlign: "center" }}>
      <Typography variant="h4" color="error">
        {messageId}
      </Typography>
    </Box>
  );
};

export default ErrorMessage;
