import { Box, Checkbox, Container, IconButton, ListItemText, MenuItem, TextField, Tooltip } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useState } from "react";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { useNavigate } from "react-router";
import { Employee, useAddEmployee } from "../clients/teamClient";
import { allLanguages } from "../assets/data/Languages";
import { queryClient } from "..";

const AddEmployee = () => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    languages: [] as unknown as "",
  });
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [email, setEmail] = useState("");
  const language = formState.languages as unknown as [];
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [lastnameError, setLastnameError] = useState(true);
  const [firstnameError, setFirstnameError] = useState(true);
  const [emailError, setEmailError] = useState(true);
  const [languageError, setLanguageError] = useState(true);
  const [passwordError, setPasswordError] = useState(true);
  const [passwordConfirmError, setPasswordConfirmError] = useState(true);
  const addEmployee = useAddEmployee();

  const handleFirstnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstname(event.target.value);
  };
  const handleLastnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastname(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    passwordConfirm === event.target.value ? setPasswordConfirmError(true) : setPasswordConfirmError(false);
    setPassword(event.target.value);
  };

  const handlePasswordRepeatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    password === event.target.value ? setPasswordConfirmError(true) : setPasswordConfirmError(false);
    setPasswordConfirm(event.target.value);
  };

  const handleFieldChange = (event: any) => {
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
    }));
  };

  const validation = () => {
    firstname.length > 0 && firstname.match(/^[a-z ,.'-]+$/i) ? setFirstnameError(true) : setFirstnameError(false);
    lastname.length > 0 && lastname.match(/^[a-z ,.'-]+$/i) ? setLastnameError(true) : setLastnameError(false);
    email.length > 0 && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? setEmailError(true) : setEmailError(false);
    password.length > 5 ? setPasswordError(true) : setPasswordError(false);
    language.length > 0 ? setLastnameError(true) : setLanguageError(false);

    if (firstnameError && lastnameError && emailError && languageError && passwordError && passwordConfirmError) {
      handleSubmit({ firstname, lastname, email, language, password });
    }
  };

  const handleSubmit = (data: Employee) => {
    addEmployee.mutate(data);
    navigate(`../team`);
  };

  return (
    <Box flexGrow="1" sx={{ mt: 5 }}>
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="flex-end">
          <IconButton aria-label="back" onClick={() => navigate(`/team`)}>
            <Tooltip title="Verwerfen">
              <ArrowBackRoundedIcon color="primary" fontSize="large" />
            </Tooltip>
          </IconButton>
          <IconButton aria-label="save" onClick={validation}>
            <Tooltip title="Speichern">
              <SaveIcon color="primary" fontSize="large" />
            </Tooltip>
          </IconButton>
        </Box>

        <Box display="flex" flex="1">
          <Box flex="1">
            <TextField
              error={firstnameError ? false : true}
              fullWidth
              label="Vorname"
              variant="standard"
              onChange={handleFirstnameChange}
              helperText={firstnameError ? "" : "Bitte geben Sie einen gültigen Vornamen ein!"}
            />
          </Box>
          <Box flex="1" sx={{ ml: 2 }}>
            <TextField
              fullWidth
              label="Nachname"
              variant="standard"
              onChange={handleLastnameChange}
              error={lastnameError ? false : true}
              helperText={lastnameError ? "" : "Bitte geben Sie einen gültigen Namen ein!"}
            />
          </Box>
        </Box>
        <Box display="flex" flex="1">
          <Box flex="1">
            <TextField
              fullWidth
              label="E-Mail"
              variant="standard"
              onChange={handleEmailChange}
              error={emailError ? false : true}
              helperText={emailError ? "" : "Email ist nicht korrekt!"}
            />
          </Box>
        </Box>
        <Box display="flex" flex="1">
          <Box flex="1">
            <TextField
              fullWidth
              select
              name="languages"
              id="languages"
              variant="standard"
              label="Sprache"
              value={formState.languages}
              SelectProps={{
                multiple: true,
                renderValue: (selected: any) => selected.join(", "),
                onChange: handleFieldChange,
              }}>
              {allLanguages.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={formState.languages.indexOf(option.value) > -1} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
        <Box display="flex" flex="1">
          <Box flex="1">
            <TextField
              fullWidth
              label="Passwort"
              variant="standard"
              type="password"
              onChange={handlePasswordChange}
              error={passwordError ? false : true}
              helperText={passwordError ? "" : "Passwört zu kurz (min. 6 Zeichen)!"}
            />
          </Box>
          <Box flex="1" sx={{ ml: 2 }}>
            <TextField
              error={passwordConfirmError ? false : true}
              fullWidth
              label="Wiederholung"
              variant="standard"
              type="password"
              onChange={handlePasswordRepeatChange}
              helperText={passwordConfirmError ? "" : "Passwörter stimmen nicht überein!"}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AddEmployee;
