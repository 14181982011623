export const portfolioList = [
  "B + M Industries",
  "Bauelemente",
  "Boden",
  "Dach & Fassade",
  "GP-Cool Speed",
  "Holzbau",
  "Putz (innen & außen)",
  "Rohbau",
  "Technische Isolierung",
  "Trockenbau",
  "Türensysteme",
];
