import * as React from "react";
import Box from "@mui/material/Box";
import { ChangeEvent, useEffect, useState } from "react";
import { Container, IconButton, TextField } from "@mui/material";
import { GridColDef, DataGrid, GridToolbarExport, GridCellParams, MuiEvent } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useAllUsers } from "../clients/userClient";
import { useNavigate } from "react-router";

const colums: GridColDef[] = [
  { field: "country", headerName: "Land" },
  {
    field: "company",
    headerName: "Firma",
    minWidth: 150,
  },
  {
    field: "firstName",
    headerName: "Vorname",
    minWidth: 100,
  },
  {
    field: "lastName",
    headerName: "Name",
    minWidth: 100,
  },
  {
    field: "city",
    headerName: "Ort",
    minWidth: 150,
  },
  {
    field: "phoneNumber",
    headerName: "Tel.",
    minWidth: 120,
  },
  {
    field: "language",
    headerName: "Sprache",
    width: 200,
  },
  {
    field: "favoriteLocation",
    headerName: "Filiale",
    width: 150,
  },
];

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}>
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: "auto",
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          "& .MuiSvgIcon-root": {
            mr: 0.5,
          },
          "& .MuiInput-underline:before": {
            borderBottom: 1,
            borderColor: "divider",
          },
        }}
      />
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} sx={{ float: "right" }} />
    </Box>
  );
}

export default function User() {
  const navigate = useNavigate();
  const userResult = useAllUsers();
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState<any[]>([]);
  const [filteredRows, setFilteredRows] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);

  useEffect(() => {
    if (userResult.status === "success") {
      if (searchText.length > 0) {
        setRows(filteredRows);
      } else {
        if (userResult.status === "success") {
          setRows(userResult.data);
        }
      }
    }
  }, [userResult, searchText.length]);

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = rows.filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setFilteredRows(filteredRows);
  };

  return (
    <Container maxWidth="lg" sx={{ height: 400, marginTop: 2 }}>
      <DataGrid
        autoHeight
        rows={rows}
        columns={colums}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50]}
        // pagination
        disableColumnMenu
        onCellClick={(params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
          event.defaultMuiPrevented = true;
          navigate(`/editUser/${params.id}`);
        }}
        components={{
          Toolbar: QuickSearchToolbar,
        }}
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(""),
          },
        }}
      />
    </Container>
  );
}
